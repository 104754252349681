import React, { useState, useEffect } from "react";
import { Select } from "antd";
import getUniqueId from "../../../utils/getUniqueKey.js";
import { DownOutlined } from "@ant-design/icons";

const { Option } = Select;

const booleanToString = (v) => {
    if (typeof v === "boolean") {
        return `${  v}`;
    } else {
        return v;
    }
};

const booleanFromString = (v) => {
    if (v === "true") {
        return true;
    } else if (v === "false") {
        return false;
    } else {
        return v;
    }
};

const filterOptionComparison = (input, option) => {
    const text = option?.children?.[0]?.props?.children;
    if (typeof input !== "string" || typeof text !== "string") {
        return false;
    } else {
        const inputToCompare = input.toLowerCase();
        const textToCompare = text.toLowerCase();
        return textToCompare.indexOf(inputToCompare) >= 0;
    }
};

const makeOptions = (options, k) => {
    return options.map((opt, i) => {
        return (
            <Option key={`${k}-option-${i}`} value={booleanToString(opt.value)}>
                <span> 
                    { opt.text } 
                </span> 
                { opt.additionalText && 
                    <div>
                        <span>{opt.additionalText}</span>
                    </div> 
                }
            </Option>
        );
    });
};
 
export const SelectableDetail = ({
    title,
    value,
    passedKey,
    containerStyle,
    defaultValue = null,
    onValueUpdated,
    strict = false,
    readOnly = false,
    multiple = false,
    blurOnSelectionId,
    options,
    loading = false,
    showArrow = false
}) => {
    const [updatedValue, setUpdatedValue] = useState(value);
    useEffect(() => {
        setUpdatedValue(value);
    });

    const k = `selectable-detail-key-${passedKey}`;

    if (strict) {
        if (typeof value === "undefined" && typeof updatedValue !== "undefined") {
            setUpdatedValue(undefined);
        }
    }

    const classNames = `${"input input--sae"}${" input--filled"}`;

    const label = (
        <label className="input__label input__label--sae">
            <span className="input__label-content input__label-content--sae">
                {title}
            </span>
        </label>
    );
    
    const blurOnSelection = blurOnSelectionId && typeof blurOnSelectionId === "string";

    const input = (
        defaultValue ? 
            <Select
                id={blurOnSelection ? blurOnSelectionId : (passedKey ?? getUniqueId())}
                mode={multiple ? "multiple" : "default"}
                loading={loading}
                value={strict ? booleanToString(value) : booleanToString(updatedValue)}
                defaultValue={defaultValue}
                className="input__field input__field--sae select-dropdown"
                onSelect={() => {
                    if (blurOnSelection) {
                        const selectEl = document.getElementById(blurOnSelectionId);
                        if (selectEl) {
                            selectEl.blur();
                        }
                    }
                }}
                onChange={(value) => {
                    if (!strict) {
                        setUpdatedValue(value);
                    }
                    onValueUpdated(
                        booleanFromString(value)
                    );
                }}
                disabled={readOnly}
                showSearch
                filterOption={filterOptionComparison}
            >
                { Array.isArray(options) && makeOptions(options, k) }
            </Select>
            : <Select
                id={blurOnSelection ? blurOnSelectionId : (passedKey ?? getUniqueId())}
                mode={multiple ? "multiple" : "default"}
                loading={loading}
                value={strict ? booleanToString(value) : booleanToString(updatedValue)}
                className="input__field input__field--sae select-dropdown"
                onChange={(value) => {
                    if (!strict) {
                        setUpdatedValue(value);
                    }
                    onValueUpdated(
                        booleanFromString(value)
                    );
                }}
                onSelect={() => {
                    if (blurOnSelection) {
                        const selectEl = document.getElementById(blurOnSelectionId);
                        selectEl.blur();
                    }
                }}
                disabled={readOnly}
                showSearch
                filterOption={filterOptionComparison}
            >
                { Array.isArray(options) && makeOptions(options, k) }
            </Select>
    );

    return (
        <div key={k} className={classNames} style={containerStyle ? containerStyle : {}}>
            {input}
            {label}
        </div>
    );
};
