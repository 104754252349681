
export const DAYS_TO_COMPLETE_REVIEW = "Days to Complete Review";
export const DAYS_TO_RESUBMIT = "Days to Resubmit";
export const DAYS_TO_REVIEW_RESUBMISSION = "Days to Complete Resubmission Review";

export const daysTypeDropdownOptions = [{label: DAYS_TO_COMPLETE_REVIEW, value: DAYS_TO_COMPLETE_REVIEW, key: "DAYS_TO_REVIEW"}, {label: DAYS_TO_RESUBMIT, value: DAYS_TO_RESUBMIT, key: "DAYS_TO_RESUBMIT"}, {label: DAYS_TO_REVIEW_RESUBMISSION, value: DAYS_TO_REVIEW_RESUBMISSION, key: "DAYS_TO_REVIEW_RESUBMISSION"}];

export const typeMapping = {
    DAYS_TO_REVIEW: DAYS_TO_COMPLETE_REVIEW,
    DAYS_TO_RESUBMIT: DAYS_TO_RESUBMIT,
    DAYS_TO_REVIEW_RESUBMISSION: DAYS_TO_REVIEW_RESUBMISSION
};
