import React from "react";
import { Col, Form, Radio, Row, Space, Input, Typography } from "antd";
const { Title } = Typography;

const warningText = ({
    isArchived,
    readOnly,
    enabledFields
}) => {
    const archivedText = "Because this category is currently archived, it cannot be modified. De-Archive first if you wish to make changes.";
    const generalWarningText = `Please be advised that you are ${ Array.isArray(enabledFields) ? "modifying" : "creating" } a new Submission Category (i.e. Contractual Deliverables, Operational Reports, etc.) and not a Submission Type.`;
    const restrictedEditingText = "If this category already has submission types, only some properties can be changed.";
    const disabledEditingText = "This category cannot be altered in any way.";
    
    if (isArchived) {
        return archivedText;
    } else if (readOnly) {
        return "";
    } else if (Array.isArray(enabledFields)) {
        return enabledFields.length > 0 
            ? `${generalWarningText}. ${restrictedEditingText}.`
            : disabledEditingText;
    } else {
        return generalWarningText;
    }
};

export const CategoryAdditionalOptions = ({
    setModified,
    modified,
    readOnly = true,
    isArchived = false,
    enabledFields, 
    modifiedFields
}) => {

    const isFieldDisabled = (name) => {
        if (Array.isArray(enabledFields)) {
            return !enabledFields.includes(name);
        } else {
            return false;
        }
    };

    const additionalText = (key) => {
        return Array.isArray(modifiedFields) && modifiedFields.includes(key) 
            ? " (Changed)" 
            : readOnly 
                ? ""
                : !isFieldDisabled(key) ? " (Editable)" : "";
    };

    const warningMessageText = warningText({ isArchived, readOnly, enabledFields });
    const Warning = warningMessageText 
        ? <h4 className="warning" style={{ textAlign: "left" }}>
            { warningMessageText }
        </h4>
        : null;

    return (
        <>
            { Warning }
            <p>Fields marked with an asterisk (<span className="asterisk">&#42;</span>) are required.</p>
            <Row>
                <Col span={24}>
                    <Form.Item
                        label={`Category Name${additionalText("name")}`}
                        name="name"
                        disabled={true}
                        rules={[{ required: true, message: "Please Enter Category Name" }]}
                    >
                        <Input
                            name="name"
                            placeholder="Category Name"
                            readOnly={readOnly || isFieldDisabled("name")}
                            value={modified.categoryName}
                            onChange={setModified}
                            disabled={readOnly || isFieldDisabled("name")}
                        />
                    </Form.Item>
                    <Title level={5}>Additional Options</Title>
                </Col>
            </Row>
            <Row>
                <Col span={12} className="category-add-options ant-form-item-label">
                    <Form.Item
                        label={`Reviewers${additionalText("reviewersOption")}`}
                        name="reviewersOption"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="reviewersOption"
                            disabled={readOnly || isFieldDisabled("reviewersOption")}
                            value={modified?.reviewersOption}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={"secondaryReviewerFlag"}>Secondary Reviewer</Radio>
                                <Radio value={"secondaryNotifierFlag"}>Secondary Notifier</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={`Bundled Submissions${additionalText("bundledSubmissionFlag")}`}
                        name="bundledSubmissionFlag"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="bundledSubmissionFlag"
                            disabled={readOnly || isFieldDisabled("bundledSubmissionFlag")}
                            value={modified?.bundledSubmissionFlag}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={true}>Enable</Radio>
                                <Radio value={false}>Disable</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={`Final Version Requirement${additionalText("finalVersionRequestFlag")}`}
                        name="finalVersionRequestFlag"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="finalVersionRequestFlag"
                            disabled={readOnly || isFieldDisabled("finalVersionRequestFlag")}
                            value={modified?.finalVersionRequestFlag}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={`Restricted Access for Non-Finalized Submissions${additionalText("readonlyFinalizedFlag")}`}
                        name="readonlyFinalizedFlag"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="readonlyFinalizedFlag"
                            disabled={readOnly || isFieldDisabled("readonlyFinalizedFlag")}
                            value={modified?.readonlyFinalizedFlag}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={`DQ Process Enabled${additionalText("dqEnabledFlag")}`}
                        name="dqEnabledFlag"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="dqEnabledFlag"
                            disabled={readOnly || isFieldDisabled("dqEnabledFlag")}
                            value={modified?.dqEnabledFlag}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={`BIDP Process Enabled${additionalText("bidpEnabledFlag")}`}
                        name="bidpEnabledFlag"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="bidpEnabledFlag"
                            disabled={readOnly || isFieldDisabled("bidpEnabledFlag")}
                            value={modified?.bidpEnabledFlag}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Enable Report Template (Non-Editable)"
                        name="reportTemplateFlag"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group
                            name="reportTemplateFlag"
                            disabled={readOnly || isFieldDisabled("reportTemplateFlag")}
                            value={modified?.reportTemplateFlag}
                            onChange={setModified}
                        >
                            <Space direction="vertical">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};