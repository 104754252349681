import React, { useState, useContext, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { NoPermission } from "../common/NoPermission/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { DueDateModal } from "../common/DueDateModal/index.js";
import { createSubmissionQuery, selectedSubmissionTypeQuery } from "./queries.js";
import { FREQUENCY_OPTIONS } from "../../constants/index.js";
import { UploadSubmissionDocument } from "../common/UploadSubmissionDocument/index.js";
import { handleGraphQLError } from "../../utils/errorHandling.js";
import { SubmissionCriteriaTable } from "./submission-criteria-table.js";
import { UserContext } from "../Application/UserContext.js";
import { getHistoryBreadcrumbs } from "../../utils/getHistoryBreadcrumbs.js";

const SPACER = <div style={{height: "80px"}}></div>;

export const CreateSubmission = () => {
    const { userOrganizationId, userPermCreateSubmission } = useContext(UserContext);
    const [modified, setModified] = useState({});
    const [dueDateListVis, setDueDateListVis] = useState(false);
    const lastHistoryBreadcrumb = getHistoryBreadcrumbs(-1);
    const navigate = useNavigate();

    const [getSubmissionTypeData, { data: selectedSubmissionTypeData }] = useLazyQuery(selectedSubmissionTypeQuery, {
        fetchPolicy: "no-cache"
    });

    const { loading, error, data } = useQuery(createSubmissionQuery, {
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        const nextToDo = selectedSubmissionTypeData?.nextToDo;
        if (selectedSubmissionTypeData?.submissionType) {
            selectedSubmissionTypeData["submissionType"]["reportTemplateFlag"] = selectedSubmissionTypeData?.submissionType?.category?.reportTemplateFlag;
        }
        
        if (nextToDo?.timeline?.currentDueDate) {
            setDueDateListVis(true);
        }
        
    }, [selectedSubmissionTypeData]);

    if (!userPermCreateSubmission) {
        return <NoPermission />;
    }

    if (error) {
        return handleGraphQLError(error);
    }

    if (loading) {
        return <LoadingContent />;
    }

    const { contractUserList } = data;
    const contractId = modified.contractId;
    const contract = contractUserList?.contracts.find(({ id }) => id === contractId);
    const selectedSubmissionType = selectedSubmissionTypeData?.submissionType;
    const contractObligations = contract?.contractType?.obligations;
    const criteriaList = selectedSubmissionType?.criteria ?? [];

    const uploadDocumentButton = 
        <div
            style={{
                float: "right"
            }}
        >
            <UploadSubmissionDocument
                create={true}
                afterUpload={() => {
                    setModified({});
                }}
                createData={
                    {
                        days: selectedSubmissionType?.daysToReview,
                        obligationId: modified?.obligationId,
                        organizationId: userOrganizationId,
                        contractId: modified?.contractId,
                        timelineId: selectedSubmissionTypeData?.nextToDo?.timeline?.id,
                        submissionTypeId: selectedSubmissionType?.id,
                        currentDueDate: selectedSubmissionTypeData?.nextToDo?.timeline?.currentDueDate
                    }
                }
            
                buttonTitle={selectedSubmissionType?.packagedSubmission ? "Add Document(s)" : "Add a Document"}
                useAttestation={selectedSubmissionType?.useAttestation}
                documentUploadTitle={`Upload New Document(s) for ${selectedSubmissionType?.name}`}
                submissionTypeData={{ submissionType: selectedSubmissionType }}
                submissionTypeId={selectedSubmissionType?.id}
                maxDocumentCount={selectedSubmissionType?.packagedSubmission ? 100 : 1}
            />
        </div>;
    
    const dueDateListModel = (
        <DueDateModal 
            options={{
                submissionType: selectedSubmissionType,
                dueDateModalVis: dueDateListVis,
                nextToDo: selectedSubmissionTypeData?.nextToDo
            }}
            newBreadcrumbs={{ label: "Create Submission", path: "/submissions/create" }}
            onCancelCallBack={() => {
                navigate(lastHistoryBreadcrumb?.path);
            }}
            onContinueCallback={() => {
                setDueDateListVis(false);
            }}
        />
    );

    const dueDateDetail = (submissionType) => {
        if (submissionType.submissionTypeNewestVersion.frequency === FREQUENCY_OPTIONS.adhoc && !submissionType.submissionTypeNewestVersion?.adhocDueDate) {
            return <p className={"spaceAbove-sm"}>Submissions of this type are submitted on an as-needed basis.</p>;
        }
        // Temporarily commenting out this code as we are not seeing this message in pre-prod.
        // return <p className={"spaceAbove-sm"}>This submission type has not been requested from your organization.</p>;
    };

    const details = (
        <div id="details" style={{ marginTop: "20px" }}> 
            <SelectableDetail
                title="Contract Type Plan"
                value={modified.contractId}
                passedKey="submission-detail-contract-type-plan-select"
                onValueUpdated={(value) => {
                    setModified({
                        ...modified,
                        contractId: value,
                        obligationId: undefined
                    });
                }}
                options={contractUserList?.contracts?.map(({ id, name }) => {
                    return {
                        text: name,
                        value: id
                    };
                })}
            />
            { contractId 
                ? <SelectableDetail
                    title="Submission Types"
                    value={modified.obligationId}
                    passedKey="submission-detail-submission-types-select"
                    onValueUpdated={(value) => {
                        const submissionType = contractObligations?.find(({ id }) => value === id)?.submissionType;

                        setModified({
                            ...modified,
                            obligationId: value
                        });

                        if (submissionType?.id) {    
                            getSubmissionTypeData({
                                variables: {
                                    submissionTypeId: submissionType.id, 
                                    organizationId: userOrganizationId 
                                }
                            });
                        }
                    }}
                    options={contractObligations?.filter(({ deleted, submissionType }) => !deleted && !submissionType.deleted).map(({ id, submissionType }) => {
                        return {
                            text: `${submissionType.specifier} - ${submissionType.name}`,
                            value: id
                        };
                    })}
                />
                : <> </>
            }
            {( selectedSubmissionType?.description &&
                <p>{ selectedSubmissionType.description }</p>
            )}
            {( selectedSubmissionType && dueDateDetail(selectedSubmissionType) )} 
        </div>
    );

    const showDocumentsAndCriteriaTable = contractId && selectedSubmissionType;
    
    return (
        <Main
            title={"New Submission"}
            details={details}
            breadcrumbs={["Create Submission"]}
        >
            { showDocumentsAndCriteriaTable && <SubmissionCriteriaTable criteriaList={ criteriaList } /> } 
            { selectedSubmissionType && uploadDocumentButton }
            {showDocumentsAndCriteriaTable && SPACER}
            { dueDateListModel }
        </Main>
    );
}; 
