import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Button,
    Modal,
    Radio,
    Spin
} from "antd";
import moment from "moment";
import { UserContext } from "../../Application/UserContext.js";
import { isoDate } from "../../../utils/functions.js";
import { SubmissionDocumentUpload } from "../DocumentUploadModal/submissionDocumentUpload.js";
import { isUndefinedOrNull } from "../../../utils/index.js";
import { SelectableDetail } from "../SelectableDetail/index.js";
import { DateableDetail } from "../DateableDetail/index.js";
import {
    CREATE_SUBMISSION,
    CREATE_VERSION
} from "../../../constants/mutations.js";

import {
    ROLE_DISPLAY_CONVERSION,
    SUBMISSION_STATUS_LIST,
    FREQUENCY_OPTIONS,
    DOCUMENT_UPLOAD_TYPES
} from "../../../constants/index.js";
import { handleMutation } from "../../../utils/errorHandling.js";
import { DueDateModal } from "../DueDateModal/index.js";
import { DownloadButton } from "../DownloadButton/index.js";

export const SUBMISSION_DOC = {
    newSubmission: "CREATE",
    addingDocumentToSubmission: "MODIFY",
    updatingDocumentVersion: "VERSION"
};

const DELAY_BEFORE_NAVIGATION = 0;

const { confirm } = Modal;

export const UploadSubmissionDocument = ({
    fromCalendar = false,
    buttonTitle,
    documentUploadTitle,
    style,
    buttonStyle,
    afterUpload,
    isDisable = true,
    maxDocumentCount,
    useAttestation,
    createData,
    submissionData,
    submissionTypeData,
    submissionTypeVersionData,
    combinedReviewers,
    create,
    newVersion,
    criteriaTable,
    setRefetchData,
    callBackMethod,
    submissionTypeId,
    dueDateModelDisplay = false,
    newBreadcrumbs
}) => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const { userPermAttestation, userAttestationRoleName, constantsData } = useContext(UserContext);

    const [createVersion, createVersionProgress] = useMutation(CREATE_VERSION);
    const [createSubmission, createSubmissionProgress] = useMutation(CREATE_SUBMISSION);
    const [modified, setModified] = useState({});
    const [reportInputVis, setReportInputVis] = useState(false);
    const [createSubmissionFiles, setCreateSubmissionFiles] = useState([]);
    const [newVersionSubmissionFiles, setNewVersionSubmissionFiles] = useState([]);
    const [attestationFormVis, setAttestationFormVis] = useState(false);
    const [dueDateListVis, setDueDateListVis] = useState(false);
    const [uploadVis, setUploadVis] = useState(false);

    const reportTemplateActiveVersion = submissionTypeData?.submissionType?.reportTemplate?.activeVersion;
    const isReportTemplateActiveVersion = submissionTypeData?.submissionType?.reportTemplate?.activeVersion?.reportTemplateStatus === "Active";

    const clearOut = () => {
        setUploadVis(false);
        setAttestationFormVis(false);
        setModified({});
        setCreateSubmissionFiles({});
        setNewVersionSubmissionFiles({});
        setReportInputVis(false);
    };

    const navigateTo = (url) => {
        if (location.pathname !== url) {
            clearOut();
            const id = setTimeout(() => {
                clearTimeout(id);
                if (fromCalendar) {
                    navigate(url, { 
                        state: { 
                            breadcrumbs: [{ label: "Calendar", path: "/calendar" }] 
                        }
                    });
                } else {
                    navigate(url);
                }
            }, DELAY_BEFORE_NAVIGATION);
        }
    };

    const safeRefetch = () => {
        if (typeof setRefetchData === "function") {
            setRefetchData(true);
        }
    };
    
    const attestationRequiredModal = () => {
        Modal.info({
            title: "Attestation Required",
            content: (
                <div>
                    <p>This action can only be completed by a CEO, CFO or one of their delegated users</p>
                </div>
            )
        });
    };

    const reportTemplateandAdhocCheck = () => {
        if (submissionTypeData?.submissionType?.reportTemplateFlag) {
            setReportInputVis(true);
        } else {
            handleUploadVisibility();
        }
    };

    const attestationAndPackagedCheck = () => {
        if (submissionTypeData?.submissionType?.packagedSubmission && create) {
            packagedSubmissionInfo();
        } else {
            reportTemplateandAdhocCheck();
        }
    };

    const openUploadDocumentModel = () => {
        if (useAttestation && !userPermAttestation) {
            attestationRequiredModal();
        }
        else if (useAttestation && userPermAttestation) {
            setAttestationFormVis(true);
        }
        else {
            attestationAndPackagedCheck();
        }
        if (callBackMethod) {
            callBackMethod();
        }
    };

    const dueDateListModel = (
        <DueDateModal 
            options={{
                submissionType: submissionTypeData?.submissionType,
                dueDateModalVis: dueDateListVis,
                setDueDateListVis: setDueDateListVis,
                nextToDo: {
                    timeline: {
                        currentDueDate: createData.currentDueDate
                    }
                }
            }}
            newBreadcrumbs={newBreadcrumbs}
            onCancelCallBack={() => {
                setDueDateListVis(false);
            }}
            onContinueCallback={() => {
                setDueDateListVis(false);
                openUploadDocumentModel();
            }}
        />
    );
    

    const checkForContainsPhi = (submissionFiles) => {
        const ele = Object.values(submissionFiles);
        let containsPiiPhiCount = 0;

        for (var i = 0; i < ele.length; i++) {
            if (!isUndefinedOrNull(ele[i].containsPii) && !isUndefinedOrNull(ele[i].containsPhi)) {
                containsPiiPhiCount++;
            }
        }
        return containsPiiPhiCount === ele.length ? false : true;
    };

    const checkAllNewVersionUploaded = () => {
        return (newVersion && Object.keys(newVersionSubmissionFiles).length === submissionData.submissionDocuments.length) ? false : true;
    };

    const packagedSubmissionInfo = () => {
        Modal.info({
            title: "Bundled Submission Info",
            destroyOnClose: true,
            content: (
                <p>
                    For Bundled Submissions, you can upload all the required document(s) needed for the submission.
                    You can&apos;t add more document(s) once the submission is created and under review.
                </p>
            ),
            onOk() {
                reportTemplateandAdhocCheck();
            }
        });
    };

    const handleUploadVisibility = () => {
        setUploadVis(true);
    };

    const packagedSubmissionConfirmation = () => {
        confirm({
            title: "Are you sure you have uploaded all the required document(s) for this submission?",
            content: "You will not be able to upload more document(s) once the submission is created. Click Submit button to create new submission or Cancel button to continue working on adding submission document(s).",
            okText: "Submit",
            onOk() {
                saveNewSubmission();
            },
            destroyOnClose: true
        });
    };


    let adhoc;
    adhoc = newVersion ? (submissionTypeVersionData?.frequency == FREQUENCY_OPTIONS.adhoc) : (submissionTypeData?.submissionType?.submissionTypeNewestVersion?.frequency == FREQUENCY_OPTIONS.adhoc);
    const reportInputModal = (
        <Modal
            title="Report Input Form"
            open={reportInputVis}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            onOk={async (e) => {
                e.preventDefault();
                if (modified.reportTemplateVersion) {
                    handleUploadVisibility();
                }
                setReportInputVis(false);

            }}
            okText="Save"
            okButtonProps={{
                disabled: (typeof modified.reportTemplateVersion === "undefined" || (adhoc ? typeof modified.adhocEndDate === "undefined" : false))

            }}
            onCancel={() => {
                clearOut();
            }}
        >

            <div>
                <SelectableDetail
                    title="Report Template Version"
                    passedKey="submission-reportTemplate-selection"
                    value={modified.reportTemplateVersion}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            reportTemplateVersion: value
                        });
                    }}
                    // upload from constantsData
                    options={constantsData.REPORT_TEMPLATE_VERSIONS.map((option) => {
                        return {
                            text: option.text,
                            value: option.text
                        };
                    })}
                />
                {(adhoc ?
                    <DateableDetail
                        title="Report Adhoc Due Date"
                        value={modified.adhocEndDate ? modified.adhocEndDate : modified.adhocEndDate}
                        onValueUpdated={(value) => {
                            setModified({
                                ...modified,
                                adhocEndDate: isoDate(value)
                            });
                        }}
                    />
                    : <></>
                )}
                {/* Download only Active version report template */}
                {isReportTemplateActiveVersion  && <>
                    <b style={{ color: "red" }}>WARNING: Ensure that the correct template version is being used prior to making a submission. <br />To download the active template version, click the button below.</b>
                    <DownloadButton style={{ marginTop: "8px" }} document={{ id: reportTemplateActiveVersion.libraryDocumentId, ...reportTemplateActiveVersion }} type={DOCUMENT_UPLOAD_TYPES.LIBRARY}
                    />
                </>}
            </div>
        </Modal>

    );

    const attestationFormModal = (
        <Modal
            title="Contractor Attestation Form"
            open={attestationFormVis}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            onOk={async (e) => {
                e.preventDefault();
                if (modified.attestationTnC) {
                    attestationAndPackagedCheck();
                }

                setAttestationFormVis(false);

            }}
            okText="Save"
            okButtonProps={{
                disabled: typeof modified.attestationTnC === "undefined"
            }}
            onCancel={() => {
                setModified({
                    ...modified,
                    attestationTnC: undefined
                });
                setAttestationFormVis(false);
            }}
        >
            <div>
                <h3>Contractor Attestation of Contractual Reporting and Information Requirements:</h3>
                <p>
                    In accordance with 42 C.F.R. § 438.606, the Contractor must provide certification concurrently with the submission of all reports, data,
                    information or other documentation (Reports and Information) required under federal and state law and under this Contract to the Department.
                    The Department has provided the appropriate formats, instructions, submission timetables, and technical assistance to Contractor for the submission of reports, data and information.
                </p>

                <h3>By choosing the Agree button, Contractor attests as follows:</h3>
                <ul>
                    <li>
                        The submitted Reports and Information comply with Department required  formats, instructions and submission timetables.
                    </li>
                    <li>
                        All Reports and Information are hereby submitted electronically or as otherwise required by the Department.
                    </li>
                    <li>
                        Based on best information, knowledge, and belief, the Reports and Information are accurate, complete, and truthful.
                    </li>
                    <li>
                        All Reports and Information are specific to each region covered in the contract.
                    </li>
                    <li>
                        If not signed by the Chief Executive Officer (CEO) or Chief Financial Officer (CFO), the person signing reports directly to the CEO or CFO and has been delegated authority by the CEO or CFO to sign.
                    </li>
                </ul>

                <h3>
                    Accept Terms and Conditions <span style={{ color: "red" }}>(mandatory)*</span>
                </h3>

                <Radio.Group
                    name="attestationTnC"
                    key="attestationTnC"
                    onChange={(e) => {
                        setModified({
                            ...modified,
                            attestationTnC: e.target.value
                        });
                    }}

                >
                    <Radio style={{ display: "block" }} value={true}>Agree</Radio>
                    <Radio style={{ display: "block" }} value={false}>Disagree</Radio>
                </Radio.Group>
                <div style={{ marginTop: "1em" }}>
                    <h3 style={{
                        display: "inline",
                        marginRight: "5px"
                    }}>
                        Attesting Authority Title:
                    </h3>
                    {ROLE_DISPLAY_CONVERSION[userAttestationRoleName]}
                </div>

            </div>
        </Modal>
    );

    /**
     * Unsetting all the modal & initializing callback function
     */
    const afterSave = () => {
        safeRefetch();
        clearOut();
        if (typeof afterUpload === "function") {
            afterUpload();
        }
    };

    const mapNewSubmisisonFileObject = () => {
        const newSubDocs = Object.values(createSubmissionFiles).map((file) => {
            return {
                nickname: typeof file.editedName === "undefined" ? file.name : file.editedName,
                documentTypeId: file.documentTypeId,
                currentStatusDueDate: isoDate(moment().add(createData.days, "days")),
                containsPii: file.containsPii,
                containsPhi: file.containsPhi,
                file: file.originFileObj,
                fileSize: (file.size).toString(),
                attestationFlag: modified.attestationTnC ? modified.attestationTnC : false,
                attestationRoleName: userPermAttestation ? userAttestationRoleName : null
            };
        });
        return newSubDocs;
    };

    const saveNewSubmission = async () => {
        /* Creating new Submission and uploading new document */
        const newSubDocs = mapNewSubmisisonFileObject();

        const createSubmissionMutation = createSubmission({
            variables: {
                newSubmission: {
                    obligationId: createData?.obligationId,
                    organizationId: createData.organizationId,
                    contractId: createData?.contractId,
                    timelineId: createData.timelineId,
                    attestationFlag: modified.attestationTnC ? modified.attestationTnC : false,
                    attestationRoleName: userPermAttestation ? userAttestationRoleName : null,
                    reportTemplateVersion: modified.reportTemplateVersion,
                    adhocEndDate: modified.adhocEndDate
                },
                newSubmissionDocuments: newSubDocs,
                submissionTypeId
            }
        });

        const createdSubmissionId = await handleMutation(createSubmissionMutation);

        if (createdSubmissionId && typeof createdSubmissionId === "string") {
            navigateTo(`/submissions/${createdSubmissionId}`);
        }
        afterSave();
    };

    const updateSubmission = async () => {

        const updateSubmissionDocuments = Object.keys(newVersionSubmissionFiles).map((id) => {
            return {
                id: newVersionSubmissionFiles[id].documentId,
                nickname: newVersionSubmissionFiles[id].metaData.nickname,
                file: newVersionSubmissionFiles[id].originFileObj,
                fileSize: (newVersionSubmissionFiles[id].size).toString(),
                attestationFlag: modified.attestationTnC ? modified.attestationTnC : false,
                attestationRoleName: userPermAttestation ? userAttestationRoleName : null,
                latestDocName: newVersionSubmissionFiles[id].originFileObj.name,
                containsPii: newVersionSubmissionFiles[id].containsPii,
                containsPhi: newVersionSubmissionFiles[id].containsPhi
            };
        });

        const updateSubmissionStatus = submissionData?.status === SUBMISSION_STATUS_LIST.final_requested ?
            SUBMISSION_STATUS_LIST.final_version_review : SUBMISSION_STATUS_LIST.review;
        const newSubDocs = mapNewSubmisisonFileObject();
        const mutation = createVersion({
            variables: {
                reviewerIds: combinedReviewers.map(({ assigneeId }) => assigneeId),
                submissionId: submissionData.id,
                status: updateSubmissionStatus,
                submissionDocuments: updateSubmissionDocuments,
                newSubmissionDocuments: newSubDocs,
                submissionTypeId,
                attestationFlag: modified.attestationTnC ? modified.attestationTnC : false,
                attestationRoleName: userPermAttestation ? userAttestationRoleName : null,
                reportTemplateVersion: modified.reportTemplateVersion,
                adhocEndDate: modified.adhocEndDate
            }
        });

        const success = await handleMutation(mutation);

        if (!success) {
            safeRefetch();
            clearOut();
            return;
        }

        afterSave();
    };

    const uploadDocumentVersion = (
        <Modal
            title={documentUploadTitle}
            open={uploadVis}
            destroyOnClose={true}
            maskClosable={false}
            closable={false}
            onOk={async (e) => {
                e.preventDefault();

                if (newVersion) {
                    updateSubmission();
                }

                if (create) {
                    if (submissionTypeData?.submissionType?.packagedSubmission) {
                        packagedSubmissionConfirmation();
                    } else {
                        saveNewSubmission();
                    }
                }

            }}
            okText={`Save ${create ? "to Create Submission" : ""}`}
            okButtonProps={{
                disabled: typeof createSubmissionFiles === "undefined" ||
                    checkForContainsPhi(createSubmissionFiles) ||
                    create && Object.keys(createSubmissionFiles).length === 0 ||
                    newVersion && checkAllNewVersionUploaded() || checkForContainsPhi(newVersionSubmissionFiles) ||
                    createSubmissionProgress.loading ||
                    createVersionProgress.loading
            }}
            cancelButtonProps={{
                disabled: createSubmissionProgress.loading || createVersionProgress.loading
            }}
            onCancel={() => {
                clearOut();
            }}
        >
            <Spin size="large" spinning={createSubmissionProgress.loading || createVersionProgress.loading}>
                {criteriaTable}
                <SubmissionDocumentUpload
                    submissionType={submissionTypeData?.submissionType}
                    documentTypes={submissionTypeData?.submissionType?.documentTypes ?? []}
                    submissionDocumentsData={submissionData?.submissionDocuments}
                    createSubmissionFiles={createSubmissionFiles}
                    setCreateSubmissionFiles={setCreateSubmissionFiles}
                    newVersionSubmissionFiles={newVersionSubmissionFiles}
                    setNewVersionSubmissionFiles={setNewVersionSubmissionFiles}
                    newVersion={newVersion ? newVersion : false}
                    finalVersion={submissionData && submissionData.status === SUBMISSION_STATUS_LIST.final_requested ? true : false}
                    maxDocumentCount={maxDocumentCount}
                />
            </Spin>
        </Modal>
    );
    return (
        <>
            <Button
                size={buttonStyle && buttonStyle.size ? buttonStyle.size : "default"}
                type={buttonStyle && !isUndefinedOrNull(buttonStyle.primary) ? buttonStyle.primary : "primary"}
                id="uploadDocument"
                style={style}
                className="spaceBelow-xs btn btn-sm btn-primary"
                disabled={isDisable ? false : true}
                onClick={() => {
                    if (create && createData?.currentDueDate && dueDateModelDisplay === true) {
                        setDueDateListVis(true);
                    } else {
                        openUploadDocumentModel();
                    }
                    
                }}
            >
                {buttonTitle}
            </Button>

            {uploadDocumentVersion}
            {attestationFormModal}
            {reportInputModal}
            {dueDateListModel}
        </>
    );
}; 
