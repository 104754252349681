import React, { useEffect } from "react";
import { Table } from "../../common/Table/index.js";
import { Table as AntdTable, Pagination } from "antd";
import { TRUE_FALSE_OPTIONS } from "../../../constants/index.js";
import { searchable } from "../../../utils/searchable.js";
import { FilterIcon } from "../../common/FilterIcon/index.js";
import { ClearFiltersButton } from "../../common/ClearFiltersButton/index.js";
import { PCDULink } from "../../common/PCDULink/index.js";
import { handleControlledDefaultSortOrder } from "../../../utils/handleControlledDefaultSortOrder.js";
import { 
    sortNotificationPreferences, 
    filterNotificationPreferences 
} from "../_shared/helpers.js";
import { PersistentState } from "../../../utils/PersistentState.js";
import { NoNotifications } from "../_shared/noNotifications.js";

const { usePersistentState } = PersistentState();

const DEFAULT_FILTER_INFO = { 
    specifier: null, 
    name: null, 
    notify: null ,
    contractType: null
};

const getInitialSelectionsAndLookup = (preferences) => { 
    const selections = [];
    const lookup = {};
    preferences.forEach(pref => {
        lookup[pref.id] = pref.active;
        if (pref.active) {
            selections.push(pref.id);
        }
    });
    return {
        selections,
        lookup
    };
};

const Container = ({ noPreferencesToRender, children }) => {
    return (
        <div className="no-table-title">
            <div style={{ marginBottom: "30px" }}>
                <h3>Submission Types</h3>
                <p>For the above selected contract types, select the submission types for which you would like to receive submission notifications. Submission type Notifications associated with muted and/or inactive Contract Types will not be displayed.</p>
            </div>
            { noPreferencesToRender 
                ? <NoNotifications />
                : children 
            }
        </div>
    );
};


export const SubmissionTypesNotification = ({
    resetFlag,
    submissionTypeModified,
    setSubmissionTypeModified,
    notificationPreferencesBySubmissionType,
    excludedContractTypes,
    loading,
    contractTypesFilterOptions
}) => {
    const defaultSortOn = "specifier";
    const defaultSortBy = "ascend";
    const { 
        selections: initialSelections, 
        lookup: initialLookup 
    } = getInitialSelectionsAndLookup(notificationPreferencesBySubmissionType);

    // persistent state :: 
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);
    const [page, setPage] = usePersistentState(1);
    const [pageSize, setPageSize] = usePersistentState(10);
    const [specifierSearchText, setSpecifierSearchText] = usePersistentState("");
    const [nameSearchText, setNameSearchText] = usePersistentState("");
    const [filteredInfo, setFilteredInfo] = usePersistentState(DEFAULT_FILTER_INFO);
    const [submissionTypeSelection, setSubmissionTypeSelection] = usePersistentState(initialSelections);

    const pageStart = (page - 1) * pageSize;
    const pageEnd = pageStart + pageSize;

    const newBreadcrumb = {
        label: "Notification Settings",
        path: "/settings"
    };

    // helper functions defined in scope of component :: 
    const clearFilters = () => {
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setPage(1);
        setPageSize(10);
        setSpecifierSearchText("");
        setNameSearchText("");
        setFilteredInfo(DEFAULT_FILTER_INFO);
        setSubmissionTypeSelection(initialSelections);
        setSubmissionTypeModified({});
    };

    useEffect(() => {
        if (resetFlag === true) { 
            setSubmissionTypeModified({});
            setSubmissionTypeSelection(initialSelections);
        }
    }, [resetFlag]);


    const modifySelections = (newSelections, selected) => {
        // normalize for single or multiple selections :: 
        const changes = Array.isArray(newSelections) ? newSelections : [newSelections];
        const selectionSet = new Set(submissionTypeSelection);
        if (selected) {
            // newly checked, so add -- 
            changes.forEach(({ id }) => selectionSet.add(id));
        } else {
            // newly unchecked, so delete -- 
            changes.forEach(({ id }) => selectionSet.delete(id));
        }
        setSubmissionTypeSelection([...selectionSet]);
    };

    
    // we are doing all filtering, sorting, and pagination on front end for this component :: 
    const dataToUse = sortNotificationPreferences({
        sortOn,
        sortBy,
        data: filterNotificationPreferences(
            notificationPreferencesBySubmissionType, 
            { 
                modified: submissionTypeModified,
                excludedContractTypes, 
                nameSearchText, 
                specifierSearchText, 
                categoryFilter: filteredInfo.category, 
                notifyFilter: filteredInfo.notify,
                contractTypeFilter: filteredInfo.contractType
            }
        )
    });

    return (
        <Container noPreferencesToRender={notificationPreferencesBySubmissionType.length === 0}>
            <Table
                id="notificationPreferencesBySubmissionType"
                size="middle"
                clearFiltersButton={<ClearFiltersButton 
                    styleToUse={{ marginBottom: "15px" }} 
                    clearFilters={clearFilters} 
                    filtersData={[
                        { currentValue: specifierSearchText, defaultValue: "" },
                        { currentValue: nameSearchText, defaultValue: "" },
                        { currentValue: filteredInfo, defaultValue: DEFAULT_FILTER_INFO } 
                    ]}
                />}
                rowSelection={{
                    selectedRowKeys: submissionTypeSelection,
                    preserveSelectedRowKeys: true,
                    onSelect: (record, selected) => {
                        modifySelections(record, selected);
                        setSubmissionTypeModified({
                            ...submissionTypeModified,
                            [record.id]: {
                                active: selected,
                                isModified: selected !== initialLookup[record.id]
                            }
                        });
                    },
                    onSelectAll: (selected, _, changeRows) => {
                        modifySelections(changeRows, selected);
                        const selectedRowObject = {};
                        changeRows.forEach(({ id }) => {
                            selectedRowObject[id] = {
                                active: selected,
                                isModified: selected !== initialLookup[id]
                            };
                        });
                        setSubmissionTypeModified({
                            ...submissionTypeModified,
                            ...selectedRowObject
                        });
                    }
                }}
                columns={handleControlledDefaultSortOrder({ 
                    sortOn, 
                    sortBy, 
                    customHandler: ({ key }) => key === sortOn 
                } , [
                    searchable({
                        title: "ID",
                        dataIndex: ["submissionType", "specifier"],
                        key: "specifier",
                        width: "10%",
                        sorter: (a, b) => a.submissionType.specifier.trim().localeCompare(b.submissionType.specifier.trim()),
                        sortDirections: ["ascend", "descend", "ascend"],
                        searchedText: specifierSearchText,
                        filteredValue: null,
                        handleSearch: (text) => {
                            setSpecifierSearchText(text);
                            setPage(1);
                        },
                        handleReset: () => setSpecifierSearchText(""),
                        render: (specifier, { submissionType }) => {
                            return <PCDULink
                                to={`/submission-types/${submissionType.id}`}
                                newBreadcrumbs={{ label: "Notification Settings", path: "/settings" }}
                            > 
                                {specifier}
                            </PCDULink>;
                        }
                    
                    }),
                    searchable({
                        title: "Name",
                        dataIndex: ["submissionType", "name"],
                        key: "name",
                        sorter: (a, b) => a.submissionType.name.trim().localeCompare(b.submissionType.name.trim()),
                        sortDirections: ["ascend", "descend", "ascend"],
                        searchedText: nameSearchText,
                        filteredValue: null,
                        handleSearch: (text) => {
                            setNameSearchText(text);
                            setPage(1);
                        },
                        handleReset: () => setNameSearchText("")
                    }),
                    {
                        title: "Contract Type",
                        dataIndex: ["submissionType", "contractType"],
                        key: "contractType",
                        sorter: (a, b) => a.submissionType.contractType.name.trim().localeCompare(b.submissionType.contractType.name.trim()),
                        sortDirections: ["ascend", "descend", "ascend"],
                        filteredValue: filteredInfo.contractType || null,
                        render: ({id, name}) => {
                            return <PCDULink to={`/contract-types/${id}`} newBreadcrumbs={newBreadcrumb}>{name}</PCDULink>;
                        },
                        filters: contractTypesFilterOptions.map(({name}) => {
                            return {
                                text: name,
                                value: name
                            };
                        }),
                        filterIcon: FilterIcon ,
                        filterSearch: true,
                        onFilter: (value, {submissionType}) => submissionType.contractType.name.includes(value)
                    },
                    AntdTable.SELECTION_COLUMN,
                    {
                        title: "Receive Notifications",
                        key: "notify",
                        width: "15%",
                        align: "center",
                        filters: TRUE_FALSE_OPTIONS.concat([{
                            text: "Unsaved Changes",
                            value: "modified"
                        }]),
                        filterIcon: FilterIcon, 
                        filteredValue: filteredInfo.notify ?? null,
                        render: (preference) => {
                            const isActive = preference.active ? "Yes" : "No";
                            if (submissionTypeModified?.[preference.id]?.isModified) {
                                const newValue = submissionTypeModified[preference.id].active ? "Yes" : "No";
                                return <span>{ newValue } {"(Unsaved Change)"} </span>;
                            } else {
                                return isActive;
                            }
                        }
                    }
                ])}
                dataSource={dataToUse.slice(pageStart, pageEnd)}
                rowKey={(preference) => (preference.id)}
                pagination={false}
                onChange={(_pagination, filters, { columnKey, order }, { action }) => {
                    if (action === "filter") {
                        setPage(1);
                    }
                    setFilteredInfo(filters);
                    setSortOn(columnKey);
                    setSortBy(order);
                }}
                loading={loading}
                locale={{
                    filterSearchPlaceholder: "Search"
                }}
            />
            <Pagination 
                current={page}
                pageSize={pageSize}
                total={dataToUse.length}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "20px 0 50px 0"
                }}
                showSizeChanger
                onChange={(targetPage, pageSize) => {
                    setPage(targetPage);
                    setPageSize(pageSize);
                }}
                pageSizeOptions={["10", "25", "50", "100",dataToUse.length]}
            />
        </Container>
    );
};