/* eslint-disable no-restricted-globals */

export const validateSession = () => {
    let myStorage = window.sessionStorage;
    console.log("session storoge", myStorage);
    var previousPath = myStorage.getItem("currentPath");
    if (previousPath == ("/entry")) {
        myStorage.setItem("loggedin", "true");
    }
    myStorage.setItem("currentPath", location.pathname);
    var isLoggedIn = myStorage.getItem("loggedin");
    return isLoggedIn;
};

export const rememberUrl = () => {
    if (window.location.pathname !== "/") {
        let myStorage = window.sessionStorage;
        if (!myStorage.getItem("redirected"))
            myStorage.setItem("redirectPath", window.location.pathname);
    }
};

export const redirectToPreLoginUrl = () => {
    let myStorage = window.sessionStorage;
    var redirectPath = myStorage.getItem("redirectPath");
    if (redirectPath) {
        myStorage.removeItem("redirectPath");
        myStorage.setItem("redirected", true);
    }
    return redirectPath;
};