import React, { useState } from "react";
import { Upload, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { InfoCircleFilled } from "@ant-design/icons";
import {
    validFileUploadedExtension,
    displayFileSizeUnit
} from "../../../utils/index.js";
import { oneGB } from "../../../constants/index.js";
import { FILE_UPLOAD_ERROR_MSG } from "../../../utils/errorHandling.js";
import { ReportTemplateFields } from "./reportTemplateFields.js";

const { Dragger } = Upload;

export const DocumentUploadModal = ({
    uploadFileMetaData,
    setUploadFileMetaData,
    maxDocumentCount,
    acceptingFileTypeList,
    additionalFileInfo,
    callback,
    children,
    displayFileInfo = true,
    isReportTemplate,
    startDate,
    setStartDate,
    latestVersionStartDate,
    version,
    setVersion,
    reportTemplateVersionsList,
    form
}) => {
    const [uploadMsg, setUploadMsg] = useState(false);
    const fileDataKeys = uploadFileMetaData ? Object.keys(uploadFileMetaData) : [];
    const isFileUploaded = fileDataKeys.length > 0 ? true : false;
    const maxUploadFileSize = oneGB;
    const acceptingFileType = ".".concat(acceptingFileTypeList.join(",."));

    const fileUploadIcon = () => {
        let size = "4x", color = "#262626";
        if (isFileUploaded) {
            color = "#237804";
        }
        return (
            <p className="ant-upload-drag-icon">
                <FontAwesomeIcon icon={faFileUpload} color={color} size={size} />
            </p>
        );
    };

    const onFileUpload = (info) => {

        if (info.file.size === 0) {
            message.error(FILE_UPLOAD_ERROR_MSG);
            info.fileList.pop();
            return;
        }

        setUploadMsg(true);
        let totalFileSize = 0;

        if (!validFileUploadedExtension(info.file, acceptingFileTypeList) || info.file.size > maxUploadFileSize) {
            info.fileList.pop();
            if (info.file.size > maxUploadFileSize) {
                message.error(`File size can not exceed more than ${maxUploadFileSize / oneGB}GB!`);

            } else {
                message.error(`${info.file.name} is not a valid file extension`);
            }

            setUploadMsg(false);
        } else {
            info.fileList.forEach((file) => {
                totalFileSize = totalFileSize + file.size;
            });

            if (totalFileSize > maxUploadFileSize) {
                message.error(`Total file size can not exceed more than ${maxUploadFileSize / oneGB}GB!`);
                info.fileList.pop();
            }

            setUploadFileMetaData({
                ...info.file
            });

            setUploadMsg(false);
            if (callback)
                callback(info, additionalFileInfo);
        }
    };

    return (
        <>
            <div style={{ display: uploadMsg ? "block" : "none" }}>
                Upload Pending......
            </div>

            <Dragger
                className="spaceBelow-sm"
                name="file"
                multiple={false}
                showUploadList={false}
                accept={acceptingFileType}
                customRequest={() => {
                    // To stop default xhr request  
                }}
                maxCount={maxDocumentCount}
                onChange={onFileUpload}
            >
                {
                    children ? (
                        children
                    ) :

                        (
                            <>
                                {fileUploadIcon()}
                                <p className="ant-upload-text">
                                    Upload your file by clicking or dragging it here
                                </p>
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ wordBreak: "break-word" }}>
                                        <InfoCircleFilled twoToneColor="#52c41a" style={{ fontSize: "15px", marginRight: "5px" }} />
                                        <span style={{ fontWeight: 600 }}>
                                            Note:
                                        </span>
                                        <span>{" "}Accepted file extensions</span>
                                        <div style={{ fontSize: "12px" }}>
                                            {acceptingFileType}
                                        </div>
                                    </div>
                                    <p>Total file size limit per upload is {maxUploadFileSize / oneGB}GB</p>
                                </div>
                            </>
                        )
                }
            </Dragger>

            {isFileUploaded && displayFileInfo && (
                <>
                    <div style={{ marginTop: 4 }}>
                        <span style={{ fontWeight: 600 }}>Name :</span> {uploadFileMetaData.name}
                    </div>
                    <div>
                        <span style={{ fontWeight: 600 }}>Size :</span> {displayFileSizeUnit(uploadFileMetaData.size)}
                    </div>
                    {isReportTemplate && 
                        <ReportTemplateFields
                            startDate = {startDate}
                            setStartDate={setStartDate}
                            latestVersionStartDate={latestVersionStartDate}
                            version={version}
                            setVersion={setVersion}
                            reportTemplateVersionsList={reportTemplateVersionsList}
                            form={form}
                        >
                        </ReportTemplateFields>}
                </>
            )}
        </>
    );
};
