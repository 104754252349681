import { gql } from "@apollo/client";

export const preloadedDataQuery = gql`
    query PreloadedDataQuery {
        businessUnits {
            id
            name
            deleted 
        }
        categories {
            id
            name
            deleted
            finalVersionRequestFlag
            readonlyFinalizedFlag
            secondaryReviewerFlag
            secondaryNotifierFlag
            bundledSubmissionFlag
            dqEnabledFlag 
            bidpEnabledFlag
            reportTemplateFlag
        }
        contractTypes (libraryAssociated: false) {
            id 
            name 
        }
        libraryAssociatedContractTypes: contractTypes (libraryAssociated: true) {
            id 
            name
        }
        documentTypes: documentTypeFilterOptions 
        constants {
            ALL_AUDIT_REPORT_TYPES
            DHHS_ORG_SPECIFIER
            STATUS_FILTERS_BY_SUBMISSION_TAB
            INVALID_SPECIFIER_CHARACTERS
            VALID_FIRST_POSITION_SPECIFIER_CHARACTERS
            CHANGELOG_ACTIONS
            CHANGELOG_ROLE_SPECIFIERS
            DOCUMENT_DQ_STATUS_DESCRIPTIONS
            ISSUE_STATUS_LIST {
                id
                enumCode
                enumType
                displayValue 
                text
                specifier
                deleted
            }
            REPORT_TEMPLATE_VERSIONS {
                id
                enumCode
                enumType
                displayValue 
                text
                specifier
                deleted
            }
            FREQUENCY_OPTIONS{
                id
                enumCode
                enumType
                displayValue 
                text
                specifier
                deleted
            }
            DISPOSITION_OPTIONS {
                id
                enumCode
                enumType
                displayValue 
                text
                specifier
                deleted
            }
            DAY_TYPE {
                id
                enumCode
                enumType
                displayValue 
                text
                specifier
                deleted
            }
            SUBMISSION_STATUS_LIST {
                id
                enumCode
                enumType
                displayValue 
                text
                specifier
                deleted
            }
            DAYS_TO_RESUBMIT {
                id
                enumCode
                displayValue
                enumType
                text
                specifier
                deleted 
            }
            DAYS_TO_REVIEW {
                id
                enumCode
                displayValue
                enumType
                text
                specifier
                deleted 
            }
            DAYS_TO_REVIEW_RESUBMISSION {
                id
                enumCode
                displayValue
                enumType
                text
                specifier
                deleted 
            }
        }
        libraryBaseFolder {
            id
            name
        }
        organizationsList {
            organizations {
                id
                name
                specifier 
            }
        }
        primaryReviewers: reviewersListFilterOptions 
        submissionTypes: submissionTypeFilterOptions 
        user(id: "me") {
            id
            name
            email
            ncid
            attestationFlag
            attestationRoleName
            isSubmitter
            auditReportTypes 
            notificationCategories {
                id 
                name 
            }
            organizations {
                id
                name
            }
            assignments {
                id
                role {
                    id
                    specifier
                }
                nodeId
            }
            permissions {
                id
                permissions
            }
        }
    }
`;