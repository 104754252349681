import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Modal, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { useUnsavedChanges } from "../../Application/UnsavedChangesContext.js";
import { PersistentState } from "../../../utils/PersistentState.js";
import { getHistoryBreadcrumbs } from "../../../utils/getHistoryBreadcrumbs.js";

const { confirm } = Modal;
const { resetAllPersistentState } = PersistentState();

const safelyExecute = (fn, args) => typeof fn === "function" ? fn(...args) : undefined;

const untwin = (breadcrumbs) => breadcrumbs.filter(({ path, label }, index, breadcrumbs) => {
    const nextBreadcrumb = breadcrumbs[index + 1];
    if (path === nextBreadcrumb?.path || label === nextBreadcrumb?.label) {
        return false;
    } else {
        return true;
    }
});

const getHandleNavigation = ({ 
    onClick, 
    resetPersistentState, 
    newBreadcrumbs, 
    resetBreadcrumbs, 
    breadcrumbIndex, 
    preventDefault
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [unsavedChanges] = useUnsavedChanges();
    const oldState = location?.state ?? {};
    const currentBreadcrumbs = getHistoryBreadcrumbs();
    let breadcrumbsToSave = resetBreadcrumbs === true 
        ? [] 
        : untwin(currentBreadcrumbs.concat(newBreadcrumbs));

    if (!resetBreadcrumbs && Number.isInteger(breadcrumbIndex)) {
        breadcrumbsToSave = breadcrumbsToSave.slice(0, breadcrumbIndex);
    }

    return (evt, destination, options = {}) => {
        if (preventDefault) {
            evt.preventDefault();
        }
        const update = options.state ?? {};
        const newState = { ...oldState, ...update, breadcrumbs: breadcrumbsToSave };
        const optionsToUse = { ...options, state: newState };

        const executeNavigation = () => {
            if (resetPersistentState === true) {
                resetAllPersistentState();
            }
            safelyExecute(onClick, [evt]);
            if (options.reloadDocument === true) {
                window.location.href = destination;
            } else {
                navigate(destination, optionsToUse);
            }
        };

        if (unsavedChanges) {
            confirm({
                title: "Unsaved Changes",
                content: "Are you sure you want to leave this page? Any unsaved changes will be lost.",
                okText: "Leave",
                cancelText: "Stay",
                onOk: executeNavigation
            });
        } else {
            executeNavigation();
        }
    };
};

export const PCDULink = ({ 
    children, 
    tooltip,
    sidebarOpen,
    resetPersistentState, 
    resetBreadcrumbs = false, 
    newBreadcrumbs = [], 
    breadcrumbIndex,
    to, 
    onClick, 
    preventDefault = true,
    ...props 
}) => {
    const newBreadcrumbsToUse = Array.isArray(newBreadcrumbs) ? newBreadcrumbs : [newBreadcrumbs];
    const handleNavigation = getHandleNavigation({ 
        onClick, 
        resetPersistentState, 
        newBreadcrumbs: newBreadcrumbsToUse.filter(Boolean), 
        breadcrumbIndex, 
        resetBreadcrumbs, 
        preventDefault: preventDefault === false ? false : true 
    });
    
    const link = <Link
        to={to}
        onClick={(evt) => handleNavigation(evt, to, props)}
        {...props}
    >
        {children}
    </Link>;
    
    return (tooltip && sidebarOpen)
        ? <Tooltip title={<div style={{ color: "black" }}>{tooltip}</div>}
            color="white" placement="bottom"> {link} </Tooltip>
        : link;
};