import moment from "moment";
import dayjs from "dayjs";

export const toMoment = (value) => {
    if (value instanceof moment) {
        return value;
    } 
    const isoString = value?.toISOString?.();
    return isoString ? moment(isoString) : moment(value);
};

// for passing a filter to a page, or multiple filters. But only one value per filter...
export const checkLocationSearch = (passedText, key) => {
    return passedText.includes(key) ?
        [passedText.substring(
            passedText.lastIndexOf(key) + key.length
        ).split(",")[0]] :
        [];
};

export const formatTimeValue = (value, includeTime = false) => {
    return includeTime
        ? moment.unix(value / 1000).format("MM/DD/YYYY, h:mm a")
        : moment.unix(value / 1000).format("MM/DD/YYYY");
};

export const formatDateEST = (value) => {
    if (!value)
        return "";
    const type = typeof value;
    let setDate = "";
    switch (type) {
        case "string": {
            const newDate = new Date(moment.unix(value / 1000));
            setDate = setESTTimezone(newDate);
            break;
        }
        case "object":{
            setDate = setESTTimezone(value);
            break;
        }
        default:
            setDate = "";
    }
    return setDate;
};

export const setESTTimezone = (date) => {
    return date.toLocaleString("en-US", {
        timeZone: "America/New_York",
        //timeZoneName: "short",
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
};

export const formatDate = (value) => {
    return toMoment(value).format("MM/DD/YYYY");
};

export const formatDatetoUTC = (value) => {
    return moment.utc(value).format("MM/DD/YYYY");
};

export const isoDate = (value) => {
    return toMoment(value).format("YYYY-MM-DD");
};

export const isoDateRange = (values) => values.map(isoDate);

export const inclusiveDateTimeRange = (values) => {
    if (Array.isArray(values) && values.length === 2) {
        return [
            toMoment(values[0]).startOf("day"),
            toMoment(values[1]).endOf("day")
        ];
    }

    return values;
};

export const extractValues = (mappings) => {
    return Object.keys(mappings);
};

export const currentUserIncludesPermission = (permissionsSet, permission, specificIds) => {
    if (typeof permissionsSet === "undefined") {
        return false;
    }

    const specificPerms = Array.isArray(specificIds) ?
        permissionsSet.filter(({ id }) => specificIds.includes(id)) :
        permissionsSet;

    const clumpedPerms = specificPerms.flatMap(({ permissions }) => permissions);

    return clumpedPerms.includes(permission);
};

export const isDocumentReviewed = (doc) => {
    const incompleteReviews = doc.newestVersion.reviews.filter(
        ({ completed }) => !completed
    );

    return incompleteReviews.length === 0;
};

export const isUndefinedOrNull = (x) => {
    return typeof x === "undefined" || x === null;
};

export const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
};

export const isASCII = str => {
    // eslint-disable-next-line no-control-regex
    return /^[\x00-\x7F]*$/.test(str);
};

export const isDateDisabled = (current) => {
    return current && current <= moment().add(-1, "days");
};

export const isStartDateDisabled = (dateOption, latestVersionStartDate, includeTodaysDate = false) => {
    let selectedDate;
    if (includeTodaysDate) {
        selectedDate = dayjs(moment(latestVersionStartDate, "YYYY/MM/DD"));
    } else {
        selectedDate = dayjs(moment(latestVersionStartDate, "YYYY/MM/DD").add(1, "days"));
    }
    return (latestVersionStartDate && dateOption < selectedDate) || isDateDisabled(dateOption);
};

const handleInput = (obj, keyOrKeysToCheck, additionalKeys = []) => {
    const checkAll = keyOrKeysToCheck === undefined && additionalKeys.length === 0;
    let userInput, toCheck;

    if (!obj || typeof obj !== "object") {
        throw new Error("Utility functions 'quickChecker' and 'hasOne' require an object (or array) as first parameter.");
    } else if (checkAll) {
        userInput = Object.keys(obj);
    } else {
        const base = Array.isArray(keyOrKeysToCheck) ? keyOrKeysToCheck : [keyOrKeysToCheck];
        userInput = base.concat(additionalKeys);
    }

    if (userInput.some(k => typeof k !== "string" && typeof k !== "number")) {
        console.error("Utility function 'quickChecker' or 'hasOne' was passed at least one key to check that was not a String or Number.\nInvalid keys are being ignored.");
        toCheck = userInput.filter(k => typeof k === "string" || typeof k === "number");
    } else {
        toCheck = userInput;
    }

    return toCheck;
};

export const hasAllOf = (obj, keyOrKeys, ...additionalKeys) => {
    const toCheck = handleInput(obj, keyOrKeys, additionalKeys);
    return toCheck.every(key => obj[key] !== null && obj[key] !== undefined && obj[key] !== "");
};

export const hasOneOf = (obj, keyOrKeys, ...additionalKeys) => {
    const toCheck = handleInput(obj, keyOrKeys, additionalKeys);
    return toCheck.some(key => obj[key] !== null && obj[key] !== undefined && obj[key] !== "");
};

export const hasNoneOf = (obj, keyOrKeys, ...additionalKeys) => {
    const toCheck = handleInput(obj, keyOrKeys, additionalKeys);
    return toCheck.every(key => obj[key] === null || obj[key] === undefined || obj[key] === "");
};

export const deduplicate = _ => Array.from(new Set(_));

export const sortDateSafely = (a, b) => {
    const dA = parseInt(a, 10);
    const dB = parseInt(b, 10);
    if (!dA && !dB) {
        return 0;
    } else if (!dA && dA !== 0) {
        return 1;
    } else if (!dB && dB !== 0) {
        return -1;
    } else {
        return dB - dA;
    }
};
export const deduplicate_objects = _ => Array.from(new Set(_.map(JSON.stringify))).map(obj => JSON.parse(obj));

export const getFilterNamesFromId = (filter, filters) => {
    const values = [];
    filters.forEach((element, index) => {
        const value = filter.find(item=>item.id == element);
        values[index] = value.name;
    });
    return values;
};

export const getFilterSpecifiersFromId = (filter, filters) => {
    const values = [];
    filters.forEach((element, index) => {
        const value = filter.find(item=>item.id == element);
        values[index] = value.specifier;
    });
    return values;
};

export const createFilterDomainFromIdName = (elements) => {
    return elements.map(({ id, name }) => ({
        value: id,
        label: name
    })).sort((a, b) => a.label.localeCompare(b.label));
};

export const userHasRole = (user, roleOrRoles) => {
    if (!Array.isArray(roleOrRoles)) {
        roleOrRoles = [roleOrRoles];
    }
    if (!roleOrRoles.every(roleSpecifier => typeof roleSpecifier === "string")) {
        console.error("Invalid role specifier argument passed to function userHasRole");
        return false;
    } else {
        return roleOrRoles.some(roleSpecifier => user?.assignments?.some(({ role }) => role?.specifier.toUpperCase() === roleSpecifier.toUpperCase()));
    }
};

export const getTimeDifferenceInMinutes = (dateToTest) => {
    const oneMinute = (1000 * 60); // milliseconds * seconds
    const todayDate = new Date();
    const recordDate = formatTimeValue(dateToTest, true);
    const recordDateTime = new Date(recordDate);
    const timeDiffInMs = todayDate.getTime() - recordDateTime.getTime();
    return Math.round(timeDiffInMs / oneMinute);
};

export const safeTruncate = (str, length, includeEllipses = true) => {
    if (!str || typeof str !== "string") {
        return "";
    } 
    const strToUse = str.trim();
    if (strToUse.length <= length) {
        return strToUse;
    } else {
        return `${strToUse.substring(0, length).trim()}${includeEllipses ? "..." : ""}`.trim();
    }
};

export const currentYear = new Date().getFullYear();

export const displayResubmissionDueDate = (submitterDueDate, initialSubmitterDueDate ) => {
    const nDayDiff = toMoment(submitterDueDate).diff(toMoment(initialSubmitterDueDate), "days");
    if (submitterDueDate) {
        return nDayDiff > 0
            ? `${formatDate(submitterDueDate)} (+${nDayDiff} days)`
            : formatDate(submitterDueDate);
    } else {
        return "N/A";
    }
};
