import React, { useLayoutEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Layout, message } from "antd";
import { ContentRouting } from "../../ContentRouting.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { NavigationBar } from "../NavigationBar/index.js";
import { NavigationSidebar } from "../NavigationSidebar/index.js";
import { validateSession,rememberUrl , redirectToPreLoginUrl} from "../ValidateSession/index.js";
import { ErrorMessage } from "../ErrorMessage/index.js";
import { Outlet, useLocation } from "react-router-dom";
import { UserContext, getUserContext } from "./UserContext.js";
import { createRegisterDownload, createRemoveFailedDownload, RegisterDownloadContext } from "./RegisterDownloadContext.js";
import { UnsavedChangesContext } from "./UnsavedChangesContext.js";
import { PreloadedData } from "./PreloadedData/index.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { preloadedDataQuery } from "./PreloadedData/preloadedDataQuery.js";

const NO_SIDE_NAV_PAGES = ["calendar", "login", "logout"];
const events = ["mousemove", "keydown", "wheel", "DOMMouseScroll", "mousewheel", "mousedown", "touchstart", "touchmove", "MSPointerDown", "MSPointerMove", "visibilitychange", "focus"];

// configure antd messages across app - 
// extra space needed at top so that the message pop up doesn't cover nav bar :: 
message.config({
    top: 60
});

export const Application = () => {
    const [currentDownloads, setCurrentDownloads] = useState([]);
    const [failedDownloads, setFailedDownloads] = useState([]);
    const [sideNavigationRefetch, setSideNavigationRefetch] = useAutoFlag(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isIdle, setIsIdle] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    // render side navigation menu for everything except calendar, "login", and notification settings 'pages' :: 
    const renderSideNav = !NO_SIDE_NAV_PAGES.some(urlFragment => location.pathname.split("/")[1] === urlFragment);

    const { error, loading, data } = useQuery(
        preloadedDataQuery,
        {
            fetchPolicy: "cache-and-network"
        }
    );

    const handleOnIdle = () => {
        setIsIdle(true);
        // Perform actions when the user is idle, e.g., logout
        fetch("/logout")
            .then(() => window.location.href = "/logged-out");
        console.log("User is idle");
    };

    const handleOnActive = () => {
        setIsIdle(false);
        if (!isIdle) {
            const redirecturl = redirectToPreLoginUrl();
            if (redirecturl) {
                navigate(redirecturl);
            }
        }
        // Perform actions when the user becomes active
        console.log("User is active");
    };

    useIdleTimer({
        timeout: 1000 * 60 * 30, // 30 minutes
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500,
        events: events
    });

    // useLayoutEffect ensures that this code executes synchronously, BEFORE any useEffect callbacks :: 
    useLayoutEffect(() => {
        setUnsavedChanges(false);
    }, [location]);

    if (data && data.user && !validateSession()) {
        console.log("hello", window.sessionStorage);
        if (window.sessionStorage.currentPath === "/submissions/sso") {
            window.location.href = "/submissions/current";
        }
        else {
            fetch("/logout")
                .then(() => window.location.href = "/entry");
            return <LoadingContent />;
        }
    }

    if (error && error.networkError?.statusCode === 401) {
        // will be caught by graphQL onError link and redirected to login
        return <LoadingContent />;
    }

    if (error) {
        return (
            <>
                <Layout.Content style={{ padding: "20px", textAlign: "center" }}>
                    <p
                        style={{
                            fontWeight: "900",
                            fontSize: "36px"
                        }}
                    >
                        Error
                    </p>
                    <ErrorMessage />
                </Layout.Content>
            </>
        );
    }

    if (!data || (!data.user || Object.keys(data.user).length === 0)) {
        if (window.location.pathname !== "/") {
            rememberUrl();
        }
    }

    if (loading) {
        return <LoadingContent />;
    }

    if (!data || (!data.user || Object.keys(data.user).length === 0)) {
        window.location.href = "/entry";
        return <LoadingContent />;
    }
    
    const userContext = getUserContext(data.user, data.constants);
    const registerDownload = createRegisterDownload(setCurrentDownloads, setFailedDownloads);
    const removeFailedDownload = createRemoveFailedDownload(setFailedDownloads);

    return (
        <UnsavedChangesContext.Provider value={{ unsavedChanges, setUnsavedChanges }}>
            <UserContext.Provider value={userContext}>
                <RegisterDownloadContext.Provider value={registerDownload}>
                    <PreloadedData initialData={data}>
                        <Layout id="pcdu-application">
                            <NavigationBar 
                                currentDownloads={currentDownloads} 
                                failedDownloads={failedDownloads} 
                                removeFailedDownload={removeFailedDownload}
                            />
                            <Layout>
                                <NavigationSidebar
                                    showSider={renderSideNav}
                                    loading={loading}
                                    sideNavigationRefetch={sideNavigationRefetch}
                                />
                                <ContentRouting
                                    setSideNavigationRefetch={setSideNavigationRefetch}
                                />
                                <Outlet />
                            </Layout>
                        </Layout>
                    </PreloadedData>
                </RegisterDownloadContext.Provider>
            </UserContext.Provider>
        </UnsavedChangesContext.Provider>
    );
};