import { useMutation, useQuery } from "@apollo/client";
import { Button, Modal, Pagination, Radio } from "antd";
import { toSvg } from "jdenticon";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../Application/UserContext.js";
import { ROLE_DISPLAY_CONVERSION,TRUE_FALSE_OPTIONS } from "../../constants/index.js";
import { MODIFY_USER } from "../../constants/mutations.js";
import { filterable, searchable } from "../../utils/index.js";
import { LoadingContent } from "../common/LoadingContent/index.js";
import { Main } from "../common/Main/index.js";
import { Table } from "../common/Table/index.js";
import { ClearFiltersButton } from "../common/ClearFiltersButton/index.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { PersistentState } from "../../utils/PersistentState.js";
import { handleGraphQLError, handleMutations } from "../../utils/errorHandling.js";
import { handleControlledDefaultSortOrder } from "../../utils/handleControlledDefaultSortOrder.js";
import { useAutoFlag } from "../../utils/useAutoFlag.js";
import { UsersCsvExport } from "./export.js";
import { usersQuery } from "./query.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const { confirm } = Modal;

const NEW_BREADCRUMB = {
    label: "Users",
    path: "/users",
    tooltip: "All Users"
};

const { usePersistentState } = PersistentState();
export const Users = () => {
    const defaultSortOn = "name";
    const defaultSortBy = "ascend";

    const { preloadedData } = usePreloadedData();
    const [modifyUser] = useMutation(MODIFY_USER);

    // normal state :: 
    const [dataToUse, setDataToUse] = useState(null);
    const [resetFilterSearch, setResetFilterSearch] = useAutoFlag(false);

    // persistent state :: 
    const [userSelection, setUserSelection] = usePersistentState([]);
    const [userTablePage, setUserTablePage] = usePersistentState(1);
    const [userTablePageSize, setUserTablePageSize] = usePersistentState(10);
    const [organizationFilter, setOrganizationFilter] = usePersistentState([]);
    const [assignmentRoleFilter, setAssignmentRoleFilter] = usePersistentState([]);
    const [serviceAccountFilter, setServiceAccountFilter] = usePersistentState([]); 
    const [activeFilter, setActiveFilter] = usePersistentState(true);
    const [nameSearch, setNameSearch] = usePersistentState("");
    const [ncidSearch, setNcidSearch] = usePersistentState("");
    const [emailSearch, setEmailSearch] = useState("");
    const [sortOn, setSortOn] = usePersistentState(defaultSortOn);
    const [sortBy, setSortBy] = usePersistentState(defaultSortBy);

    const {
        userPermArchiveUsers,
        userPermCreateUsers,
        userPermEditUsers,
        userIsSubmitter,
        userDhhsUsers
    } = useContext(UserContext);

    const clearSearch = () => {
        setUserTablePage(1);
        setUserTablePageSize(10);
        setSortOn(defaultSortOn);
        setSortBy(defaultSortBy);
        setActiveFilter(true);
        setServiceAccountFilter([]);
        setOrganizationFilter([]);
        setAssignmentRoleFilter([]);
        setNameSearch("");
        setNcidSearch("");
        setEmailSearch("");
        setUserSelection([]);
        setResetFilterSearch(true);
    };

    const queryVariables = {
        offset: (userTablePage - 1) * userTablePageSize,
        limit: userTablePageSize,
        organizationIds: organizationFilter,
        assignmentRoleIds: assignmentRoleFilter,
        activeFilter,
        serviceAccountFilter,
        nameSearch,
        ncidSearch,
        emailSearch,
        sortOn,
        sortBy
    };

    const { loading, error, data, refetch } = useQuery(usersQuery, {
        variables: queryVariables,
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if (data) {
            setDataToUse(data);
        }
    },[data]);

    if (error) {
        return handleGraphQLError(error);
    }

    if (!dataToUse){
        return (
            <LoadingContent />
        );
    }

    const { organizations } = preloadedData;
    const { usersList, roles } = dataToUse;
    const { users, count: usersCount } = usersList;

    const showConfirmArchive = () => {
        const usernames = users
            .filter(({ id }) => userSelection.includes(id))
            .map(({ name }) => name);

        confirm({
            title:
                `Do you really want to ${activeFilter ? "Deactivate" : "Activate"}  ${userSelection.length === 1 ? "User" : "Users"}: ${usernames.join(", ")
                }?`,
            okText: "Yes",
            async onOk() {
                const mutations = userSelection.map((id) => {
                    return modifyUser({
                        variables: {
                            id,
                            newUser: { active: !activeFilter }
                        }
                    });
                });

                // ERROR HANDLER ITERATION 
                await handleMutations(mutations, {
                    showSuccess: true,
                    successMessage: `${userSelection.length === 1 ? "User" : "Users"} successfully modified`
                });

                setUserSelection([]);
                refetch();
            }
        });
    };
    const rightAlignedRadioButtons = userPermEditUsers 
        ? <Radio.Group
            defaultValue = {activeFilter}
            value={activeFilter}
            onChange = {(e) => {
                setActiveFilter(e.target.value);
                setUserTablePage(1);
            }}
            buttonStyle="solid"
        >
            <Radio.Button value={true} >Active Users</Radio.Button>
            <Radio.Button value={false}>Inactive Users</Radio.Button>
        </Radio.Group> 
        : null;

    let userTableColumn = [
        {
            dataIndex: "email",
            width: 50,
            render: (email) => {
                return (
                    <div
                        style={{
                            display: "flex"
                        }}
                        dangerouslySetInnerHTML={{
                            __html: toSvg(email, 40)
                        }}
                    />
                );
            }
        },
        searchable({
            title: "Name",
            dataIndex: "name",
            width: "25%",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            render: (name, { id }) => {
                return (<PCDULink to={`/users/${id}`}>{name}</PCDULink>);
            },
            handleSearch: (textToSearch) => {
                setNameSearch(textToSearch);
            },
            setPage: setUserTablePage,
            handleReset: () => {
                setNameSearch("");
            },
            searchedColumn: () => { },
            searchedText: nameSearch
        }),
        searchable({
            title: "NCID",
            dataIndex: "ncid",
            width: "15%",
            sorter: true,
            sortDirections: ["ascend", "descend", "ascend"],
            handleSearch: setNcidSearch,
            setPage: setUserTablePage,
            searchedText: ncidSearch,
            handleReset: () => setNcidSearch("")
            
        }),
        filterable({
            title: "Organization",
            dataIndex: "organizations",
            width: "25%",
            searchFilters: true,
            resetFilterSearch,
            filter: organizationFilter,
            setFilter: setOrganizationFilter,
            setPage: setUserTablePage,
            domain: organizations.map(({ id, name }) => {
                return {
                    label: name,
                    value: id
                };
            }),
            render: (cellData) => {
                return cellData.map(({ id, name }, i) => {
                    return (
                        <span key={`cell-data-org-list-${i}`}>
                            <PCDULink 
                                to={`/organizations/${id}`}
                                newBreadcrumbs={NEW_BREADCRUMB}
                            >
                                {name}
                            </PCDULink>
                            <br />
                        </span>
                    );
                });
            }
        }),
        userIsSubmitter ?
            {
                title: "Role",
                dataIndex: "roles",
                width: "25%",
                render: (roles) => {
                    const text = roles
                        .map((role) => ROLE_DISPLAY_CONVERSION[role.specifier])
                        .join(", ");
                    return (<span>{text}</span>);
                }
            } :
            filterable({
                title: "Role",
                dataIndex: "roles",
                width: "25%",
                searchFilters: true,
                resetFilterSearch,
                filter: assignmentRoleFilter,
                setFilter: setAssignmentRoleFilter,
                setPage: setUserTablePage,
                domain: roles.map(({ id, specifier }) => {
                    return {
                        label: ROLE_DISPLAY_CONVERSION[specifier],
                        value: id
                    };
                }),
                render: (roles) => {
                    const text = roles
                        .map((role) => ROLE_DISPLAY_CONVERSION[role.specifier])
                        .join(", ");

                    return (<span>{text}</span>);
                }
            })
    ];

    if (userDhhsUsers) {
        userTableColumn.push(
            filterable({
                title: "Service Account",
                dataIndex: "serviceAccount",
                width: "10%",
                searchFilters: true,
                resetFilterSearch,
                filter: serviceAccountFilter,
                setFilter: setServiceAccountFilter,
                setPage: setUserTablePage,
                domain: TRUE_FALSE_OPTIONS.map(({text, value}) => {
                    return {
                        label: text,
                        value: value
                    };
                }),
                render: (cellData) => {
                    const text = cellData ? "Yes" : "No";
                    return (<span>{text}</span>);
                }
            })
        );
    }

    if (userPermEditUsers) {
        userTableColumn.push(
            {
                title: "Status",
                dataIndex: "active",
                width: "15%",
                render: (cellData) => {
                    const text = userPermCreateUsers ?
                        cellData ? "Active User" : "Disabled User" : " ";
                    return (<span>{text}</span>);
                }
            }
        );
    }

    const usersTable = (
        <Table
            exportButton={<UsersCsvExport variables={queryVariables} showStatus={userPermEditUsers} />}
            clearFiltersButton={<ClearFiltersButton 
                clearFilters={clearSearch} 
                filtersData={[
                    { currentValue: organizationFilter, defaultValue: [] },
                    { currentValue: assignmentRoleFilter, defaultValue: [] },
                    { currentValue: activeFilter, defaultValue: true },
                    { currentValue: serviceAccountFilter,defaultValue: [] },
                    { currentValue: nameSearch, defaultValue: "" },
                    { currentValue: ncidSearch, defaultValue: "" },
                    { currentValue: emailSearch, defaultValue: "" } 
                ]}
            />}
            rightAlignedButtonBarElement={rightAlignedRadioButtons}
            id="usersTable"
            style={{
                backgroundColor: "#fff"
            }}
            size="middle"
            pagination={false}
            getPopupContainer={() => document.getElementById("main")}
            placement="topRight"
            columns={handleControlledDefaultSortOrder({ sortOn, sortBy }, userTableColumn)}
            dataSource={users}
            rowKey={(user) => user.id}
            rowSelection={userPermArchiveUsers && {
                selectedRowKeys: userSelection,
                preserveSelectedRowKeys: true,
                onChange: (selectedRowKeys) => {
                    setUserSelection(selectedRowKeys);
                }
            }}
            loading={loading}
            onChange={(_pagination, _filters, { field, order }) => {
                setSortOn(field);
                setSortBy(order);
            }}

        />
    );

    const usersTablePagination = (
        <Pagination
            style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0 50px 0"
            }}
            showSizeChanger
            onShowSizeChange={(targetPage, pageSize) => {
                setUserTablePage(targetPage);
                setUserTablePageSize(pageSize);
            }}
            onChange={(targetPage, pageSize) => {
                setUserTablePage(targetPage);
                setUserTablePageSize(pageSize);
            }}
            current={userTablePage}
            pageSize={userTablePageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            total={usersCount}
        />
    );

    return (
        <Main
            title={"All  Users"}
            breadcrumbs={["Users"]}
            details={
                <PCDULink
                    className="ant-btn ant-btn-primary link-button"
                    to="/users/create"
                    style={{
                        float: "right",
                        display: userPermCreateUsers ? "initial" : "none"
                    }}
                >
                    Create a User
                </PCDULink>
            }
        >
            <div className="main-content-margin-top">
                <Button
                    style={{
                        display: userPermArchiveUsers ? "block" : "none",
                        marginBottom: "15px"
                    }}
                    className="ownLine"
                    type="danger"
                    size="small"
                    onClick={showConfirmArchive}
                    disabled={userSelection.length <= 0}
                >
                    {activeFilter ? "Deactivate" : "Activate"} {userSelection.length} {userSelection.length === 1 ? "User" : "Users"}
                </Button>
                {usersTable}
                {usersTablePagination}
            </div>
        </Main>
    );
};
