import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Layout, Menu, Affix, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    SettingOutlined, 
    DoubleRightOutlined, 
    DoubleLeftOutlined, 
    CloseCircleOutlined, 
    ExpandAltOutlined,
    PartitionOutlined, 
    ProfileOutlined,
    FileZipOutlined, 
    ExportOutlined,
    BellOutlined,
    BookOutlined
} from "@ant-design/icons";
import { 
    faBook, 
    faAddressBook, 
    faBuilding, 
    faUsersLine, 
    faGear, 
    faHandshake,
    faListCheck
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { UserContext } from "../Application/UserContext.js";
import { 
    deStructureCategoryName, 
    safeTruncate 
} from "../../utils/index.js";
import { LibrarySidebar } from "./library-sidebar.js";
import { SubmissionOptionsSortedByRole } from "./submission-options-sorted-byrole.js";
import { PCDULink } from "../common/PCDULink/index.js";
import { usePreloadedData } from "../Application/PreloadedData/index.js";

const MILLISECOND_DELAY_FOR_SIDER_COLLAPSE_ANIMATION = 400;
const OPEN_SIDER_WIDTH = 260;
const AJAR_SIDER_WIDTH = 100;
const STICKY_BUTTON_OFFSET = 180;

const { Sider } = Layout;


export const NavigationSidebar = (({ 
    showSider,
    loading,
    sideNavigationRefetch 
}) => {
    const intervalRef = useRef();
    const { preloadedData, refetchPreloadedData } = usePreloadedData();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [sidebarRemoved, setSidebarRemoved] = useState(false);
    const [showStickyExpand, setShowStickyExpand] = useState(false);
    const [showSidebarContent, setShowSidebarContent] = useState(true);
    const [stateOpenKeys, setStateOpenKeys] = useState(["notification-settings"]);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const location = useLocation();

    const {
        userPermCreateSubTypeCategory,
        userPermViewChangeLog,
        userIsContractAdmin,
        userIsSystemAdmin,
        userIsSuperUser,
        userPermViewUserAuditLog
    } = useContext(UserContext);

    const iconify = (text) => sidebarOpen ? null : <span style={{ marginLeft: "-20px" }}>{ text }</span>;

    const removeSidebar = () => {
        if (!sidebarRemoved) {
            clearTimeout(intervalRef.current);
            setSidebarRemoved(true);
            setShowSidebarContent(false);
            setSidebarOpen(false);
            intervalRef.current = setTimeout(() => {
                clearTimeout(intervalRef.current);
                setShowStickyExpand(true);
            }, MILLISECOND_DELAY_FOR_SIDER_COLLAPSE_ANIMATION);
        }
    };

    const addSidebar = () => {
        if (sidebarRemoved) {
            clearTimeout(intervalRef.current);
            setShowStickyExpand(false);
            setSidebarRemoved(false);
            setSidebarOpen(true);
            intervalRef.current = setTimeout(() => {
                clearTimeout(intervalRef.current);
                setShowSidebarContent(true);
            }, MILLISECOND_DELAY_FOR_SIDER_COLLAPSE_ANIMATION);
        }
    };

    const toggleSidebarCollapse = () => {
        clearTimeout(intervalRef.current);
        setShowSidebarContent(false);
        setSidebarOpen(!sidebarOpen);
        intervalRef.current = setTimeout(() => {
            clearTimeout(intervalRef.current);
            setShowSidebarContent(true);
        }, MILLISECOND_DELAY_FOR_SIDER_COLLAPSE_ANIMATION);
    };

    // clear timer that may be going if sidebar is in process of being removed :: 
    useEffect(() => () => clearTimeout(intervalRef.current), []);

    useEffect(() => {
        if (sideNavigationRefetch === true) {
            refetchPreloadedData();
        }
    }, [sideNavigationRefetch]);

    // using initial data loaded flag to hide the sidebar buttons until after initial data load; otherwise, on initial render 
    // of app the buttons appear higher on the sidebar and then "jump" down to bottom  
    useEffect(() => {
        if (!loading && preloadedData && !initialDataLoaded) {
            setInitialDataLoaded(true);
        }
    }, [loading]);

    const path = location.pathname.charAt(location.pathname.length - 1) === "/"
        ? location.pathname.substring(0, location.pathname.length - 1)
        : location.pathname;

    /* if the notification settings are minimized and user navigated to a different page, 
    default to library notification settings page
    */
    useEffect(() => {
        if (path === "/settings/library") {
            setStateOpenKeys(["notification-settings"]);
        }
    }, [path]);

    const submissionMenuItems = SubmissionOptionsSortedByRole();
    
    const submissionsOptions = (
        <Menu
            className={ sidebarOpen ? "reduced-padding-sidebar" : "" }
            mode="inline"
            style={{ height: "100%", boderRight: 0 }}
            selectedKeys={path.substring(path.lastIndexOf("/") + 1)}
            items={ submissionMenuItems }
        />
    );

    const documentsOptions = (
        <Menu
            mode="inline"
            style={{ height: "100%", boderRight: 0 }}
            selectedKeys={[path.substring(1)]}
            items={[
                {
                    key: "documents",
                    icon: <ProfileOutlined />,
                    label: <PCDULink 
                        to="/documents" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        All Documents
                    </PCDULink>
                }, 
                {
                    key: "documents/archived",
                    icon: <FileZipOutlined />,
                    label: <PCDULink 
                        to="/documents/archived" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        1.0 Final Deliverables
                    </PCDULink>
                }
            ]}
        />
    );

    const categoryList = preloadedData?.categories?.map?.(({ id, name }) => {
        return {
            key: id, 
            icon: iconify(safeTruncate(name, 6)),
            label: <PCDULink
                to={`/submission-types/category/${id}`}
                resetPersistentState={true}
                resetBreadcrumbs={true}
                tooltip = { name }
                sidebarOpen={sidebarOpen}
            >
                { name }
            </PCDULink>
        };
    }) ?? [];

    const submissionTypeSettingsChildren = [
        {
            key: "category",
            icon: <FontAwesomeIcon icon={faGear} />,
            label: <PCDULink 
                to="/submission-types/category" 
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                    Categories
            </PCDULink>
        }, 
        {
            key: "business-unit",
            icon: <FontAwesomeIcon icon={faGear} />,
            label: <PCDULink 
                to="/submission-types/business-unit" 
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                Business Units
            </PCDULink> 
        },
        {
            key: "other-settings",
            icon: <FontAwesomeIcon icon={faGear} />,
            label: <PCDULink 
                to="/submission-types/other-settings" 
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                Other Settings
            </PCDULink> 
        }
    ];

    const categorySettingsChildren = preloadedData?.categories?.map((category) => ({
        key: `notification-settings-category-${category.id}`,
        label: <PCDULink
            to={`/settings/category/${category.id}`}
            resetPersistentState={true}
            resetBreadcrumbs={true}
            tooltip = {category.name}
            sidebarOpen={sidebarOpen}
        >
            {category.name}
        </PCDULink>
    })) ?? [];

    const notificationSettingsChildren = [
        {
            key: "notification-settings-library",
            icon: <BookOutlined/>,
            label: <PCDULink
                to="/settings/library"
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                Library Settings
            </PCDULink>
        },
        categorySettingsChildren.length > 0 
            ? {
                key: "notification-settings-category",
                icon: <FontAwesomeIcon icon={faListCheck} />,
                label: <span>By Category</span>,
                children: categorySettingsChildren
            }
            : null
    ].filter(Boolean);

    const menuItems = [
        {
            key: "notification-settings",
            icon: <BellOutlined />,
            label: <PCDULink
                to="/settings/library"
                resetPersistentState={true}
                resetBreadcrumbs={true}
            >
                Notification Settings
            </PCDULink>,
            children: notificationSettingsChildren
        }
    ];

    const getLevelKeys = (items1) => {
        const key = {};
        const func = (items2, level = 1) => {
            items2.forEach((item) => {
                if (item.key) {
                    key[item.key] = level;
                }
                if (item.children) {
                    func(item.children, level + 1);
                }
            });
        };
        func(items1);
        return key;
    };

    const levelKeys = getLevelKeys(menuItems);

    const onOpenChange = (openKeys) => {
        const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);
        // open
        if (currentOpenKey !== undefined) {
            const repeatIndex = openKeys
                .filter((key) => key !== currentOpenKey)
                .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

            setStateOpenKeys(
                openKeys
                    // remove repeat key
                    .filter((_, index) => index !== repeatIndex)
                    // remove current level all child
                    .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
            );
        } else {
            // close
            setStateOpenKeys(openKeys);
        }
    };

    const notificationSettingsOptions = (
        <Menu
            style={{ height: "100%" }}
            selectedKeys={path === "/settings/library" ? "notification-settings-library" : `notification-settings-category-${path.split("/").slice(-1)}`}
            defaultSelectedKeys={["notification-settings"]}
            openKeys={stateOpenKeys}
            onOpenChange={onOpenChange}
            mode="inline"
            items={menuItems.filter(Boolean)}
        />
    );

    // include full title for outer menu of submission type settings when sidebar is collapsed :: 
    if (!sidebarOpen) {
        submissionTypeSettingsChildren.unshift({
            key: "parent-menu-name-submission-type-settings",
            label: <h3 className="inert-menu-item"> Settings </h3>,
            disabled: true, 
            className: "inert-menu-item"
        });
    }

    const submissionTypeSettings = !userPermCreateSubTypeCategory 
        ? []
        : [
            {
                key: "submission-types-settings", 
                icon: <SettingOutlined />,
                label: <span> Settings </span>, 
                children: submissionTypeSettingsChildren 
            }
        ];


    const isSubmissionTypesSettingsPage = path === "/submission-types/category" || path === "/submission-types/business-unit";
    const submissionTypesOptions = (
        <Menu
            mode="inline"
            style={{ height: "100%", boderRight: 0 }}
            selectedKeys={[deStructureCategoryName(path.substring(path.lastIndexOf("/") + 1))]}
            defaultOpenKeys={isSubmissionTypesSettingsPage ? ["submission-types-settings"] : []}
            items={[
                {
                    key: "submission-types",
                    icon: iconify("All"),
                    label: <PCDULink 
                        to="/submission-types" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        All Submission Types
                    </PCDULink> 
                }, 
                ...categoryList, 
                ...submissionTypeSettings 
            ]}
        />
    );

    const analyticsOptions = (
        <Menu
            style={{ height: "100%" }}
            selectedKeys={path.split("/")}
            defaultOpenKeys={[]}
            mode="inline"
            items={[
                {
                    key: "export-tables",
                    icon: <ExportOutlined />,
                    label: <PCDULink 
                        to="/export-tables" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        Export Tables
                    </PCDULink>
                }, 
                userPermViewUserAuditLog && {
                    key: "web-logs",
                    icon: <FontAwesomeIcon icon={faAddressBook} />,
                    label: <PCDULink 
                        to="/web-logs" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        User Audit Logs
                    </PCDULink>
                },
                userPermViewChangeLog && {
                    key: "changelogs",
                    icon: <FontAwesomeIcon icon={faBook} />,
                    label: <PCDULink 
                        to="/changelogs" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        Audit Changelogs
                    </PCDULink>
                }
            ].filter(Boolean)}
        />
    );

    const directoryOptions = (
        <Menu
            mode="inline"
            style={{ height: "100%", boderRight: 0 }}
            selectedKeys={path.substring(1)}
            items={[
                {
                    key: "organizations", 
                    icon: <FontAwesomeIcon icon={faBuilding} />,
                    label: <PCDULink 
                        to="/organizations" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        Organizations
                    </PCDULink> 
                }, 
                {
                    key: "users", 
                    icon: <FontAwesomeIcon icon={faUsersLine} />,
                    label: <PCDULink 
                        to="/users" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true} 
                    >
                        Users
                    </PCDULink> 
                }
            ]}
        />
    );

    const contractsOptions = (
        <Menu
            mode="inline"
            style={{ height: "100%", boderRight: 0 }}
            selectedKeys={path.substring(1)}
            items={[
                {
                    key: "contracts",
                    icon: <FontAwesomeIcon icon={faHandshake} />,
                    label: <PCDULink 
                        to="/contracts" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        Contracts
                    </PCDULink>
                }, 
                {
                    key: "contract-types",
                    icon: <PartitionOutlined />,
                    label: <PCDULink 
                        to="/contract-types" 
                        resetPersistentState={true}
                        resetBreadcrumbs={true}
                    >
                        Contract Types
                    </PCDULink> 
                }
            ]}
        />
    );

    let displayed = null;
    let splitPath = path.split("/");

    if (path === "") {
        displayed = submissionsOptions;
    } else
        switch (splitPath[1]) {
            case "organizations":
            case "users":
                displayed = directoryOptions;
                break;

            case "contract-types":
            case "contracts":
                displayed = contractsOptions;
                break;

            case "submission-types":
                displayed = submissionTypesOptions;
                break;

            case "submissions":
                displayed = submissionsOptions;
                break;

            case "documents":
            case "archived":
                displayed = documentsOptions;
                break;

            case "library":
                displayed = <LibrarySidebar 
                    data={preloadedData}
                    path={path}
                    sidebarOpen={sidebarOpen}
                    userIsContractAdmin={userIsContractAdmin}
                    userIsSuperUser={userIsSuperUser}
                    userIsSystemAdmin={userIsSystemAdmin}
                />;                
                break;

            case "export-tables":
            case "web-logs":
            case "changelogs":
                displayed = analyticsOptions;
                break;
            case "settings":
                displayed = notificationSettingsOptions;
                break;
            default:
                break;
        }


    const StickyExpandButton = <Affix offsetTop={STICKY_BUTTON_OFFSET} className="sidebar-expand-affix">
        <Button 
            className={`sidebar-expand-button${ !showStickyExpand ? " hidden" : ""}`}
            shape="circle"
            icon={ <ExpandAltOutlined /> }
            onClick={addSidebar}
        />
    </Affix>;

    const SidenavTriggerButtons = <div> 
        <Divider style={{ marginBottom: "5px", "marginTop":"0px" }} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button 
                disabled={sidebarRemoved}
                type="default"
                icon={ <CloseCircleOutlined /> }
                onClick={removeSidebar}
                style={{ border: "none", boxShadow: "none" }}
            >
                { sidebarOpen ? "Close" : "" }
            </Button>
            <Button 
                disabled={sidebarRemoved}
                type="default"
                icon={ sidebarOpen ? <DoubleLeftOutlined /> : <DoubleRightOutlined /> } 
                onClick={toggleSidebarCollapse}
                style={{ border: "none", boxShadow: "none" }}
            >
                { sidebarOpen ? "Collapse" : "" }
            </Button>
        </div>
    </div>;

    return <Sider 
        style={{
            textOverflow: "ellipsis",
            display: showSider ? "initial" : "none" 
        }}
        theme="light" 
        className="nav-sidebar" 
        width={ OPEN_SIDER_WIDTH }
        collapsed={ !sidebarOpen }
        collapsedWidth={ sidebarRemoved ? 0 : AJAR_SIDER_WIDTH }
        trigger={ showSidebarContent && initialDataLoaded ? SidenavTriggerButtons : null}
        zeroWidthTriggerStyle={{ display: "none" }}
        collapsible 
    >
        <div 
            className="sidebar-container" 
            style={{ 
                visibility: showSidebarContent ? "visible" : "hidden", 
                position: "sticky",
                top: "64px",
                zIndex: "9" 
            }}
        >
            { StickyExpandButton }
            { displayed }
        </div>
    </Sider>;
});