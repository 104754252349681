import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Button, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { usePreloadedData } from "../../Application/PreloadedData/index.js";
import { UserContext } from "../../Application/UserContext.js";
import { NoPermission } from "../../common/NoPermission/index.js";
import { Main } from "../../common/Main/index.js";
import { LoadingContent } from "../../common/LoadingContent/index.js";
import { SelectableDetail } from "../../common/SelectableDetail/index.js";
import { ClearFiltersButton } from "../../common/ClearFiltersButton/index.js";
import { handleGraphQLError } from "../../../utils/errorHandling.js";
import { useAutoFlag } from "../../../utils/useAutoFlag.js";
import { getLibraryLinkData } from "../../../utils/getLibraryLinkData.js";
import { getContractTypesQuery, getFolderPathwayQuery } from "../queries.js";
import { LibrarySearchResults } from "./results.js";


export const LibrarySearch = () => {
    const navigate = useNavigate();
    const { preloadedData } = usePreloadedData();
    const [search, setSearch] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [folders, setFolders] = useState([]); 
    const [libraryDocumentTypes, setLibraryDocumentTypes] = useState([]);
    const [inputHasChanged, setInputHasChanged] = useState(false);
    const [resetFilters, setResetFilters] = useAutoFlag(false);
    
    const {
        userPermViewLibDoc,
        userPermViewAssociatedLibDoc
    } = useContext(UserContext);

    const userHasPermission = userPermViewLibDoc || userPermViewAssociatedLibDoc;

    const { loading, error, data } = useQuery(
        getContractTypesQuery,
        {
            fetchPolicy: "no-cache"
        }
    ); 

    const [ getFolderPathway, { 
        loading: folderPathwayLoading, 
        error: folderPathwayError, 
        data: folderPathwayData 
    }] = useLazyQuery(getFolderPathwayQuery);

    useEffect(() => { 
        if (folderPathwayData) {
            const { libraryFolderPathway: pathway } = folderPathwayData;
            const redirectObject = getLibraryLinkData(pathway); 
            if (!redirectObject) {
                message.error("There was an error redirecting to the selected folder."); 
            } else {
                navigate(redirectObject.pathname, redirectObject); 
            }
        }
    }, [folderPathwayData]); 

    if (error) {
        return handleGraphQLError(error);
    }

    if (folderPathwayError) {
        return handleGraphQLError(folderPathwayError);
    }

    if (loading || folderPathwayLoading) {
        return <LoadingContent delay={600} />;
    }

    if (!userHasPermission) {
        return <NoPermission />;
    }    

    const clearOut = () => {
        setInputHasChanged(false);
        setFolders([]);
        setLibraryDocumentTypes([]);
        setSearchInput("");
        setSearch(null);
    };

    const contractTypeOptions = data?.contractTypes ?? [];
    const libraryBaseFolderOptions = preloadedData?.libraryBaseFolder ?? [];

    const contractTypeDropdown = <div className="category-dropdown">
        <SelectableDetail
            title={"Contract Types"}
            value={folders}
            strict={true}
            onValueUpdated={(selectedOptions) => {
                setInputHasChanged(true);
                setFolders(selectedOptions);
            }}
            showArrow={true}
            multiple={true}
            blurOnSelectionId={"library-search-ct-dropdown"}
            options={contractTypeOptions.map(({ id, name }) => {
                return {
                    key: id,
                    text: `${name}`,
                    value: id
                };
            })}
        />
    </div>; 

    const libraryDocumentTypesDropdown = <div className="category-dropdown">
        <SelectableDetail
            title={"Library Document Types"}
            value={libraryDocumentTypes}
            strict={true}
            onValueUpdated={(selectedOptions) => {
                setInputHasChanged(true);
                setLibraryDocumentTypes(selectedOptions);
            }}
            showArrow={true}
            multiple={true}
            blurOnSelectionId={"library-search-ldt-dropdown"}
            options={libraryBaseFolderOptions.map(({ id, name }) => {
                return {
                    key: id,
                    text: name,
                    value: id
                };
            })}
        />
    </div>; 

    const handleSearchInput = () => {
        const searchInputToUse = searchInput.trim();
        if (!searchInputToUse) {
            message.error({
                content: "Invalid search input"
            });
        } else {
            setInputHasChanged(false);
            setSearch({
                contractTypes: [...folders],
                baseFolders: [...libraryDocumentTypes],
                name: searchInputToUse
            });
        }
    };

    return (
        <Main
            title="Search Library Documents"
            breadcrumbs={
                ["Library Documents", "Search"]
            }
        >
            <div>
                <div style={{ marginTop: "15px" }}>
                    <div style={{ marginBottom: "15px", width: "389px" }}>
                        <Input
                            placeholder={"Enter text to search Library files based on name..."}
                            value={searchInput}
                            onChange={(e) => {
                                setInputHasChanged(true);
                                setSearchInput(e.currentTarget.value);
                            }}
                            onPressEnter={handleSearchInput}
                        />
                    </div>
                    {contractTypeOptions.length > 0 ? contractTypeDropdown : null}
                    {libraryBaseFolderOptions.length > 0 ? libraryDocumentTypesDropdown : null}
                    <div style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "260px"
                    }}>
                        <div>
                            <ClearFiltersButton
                                clearFilters={() => {
                                    clearOut();
                                    setResetFilters(true);
                                }}
                                style={{
                                    float: "right",
                                }}
                                type="primary"
                                filtersData={[
                                    {
                                        currentValue: folders,
                                        defaultValue: []
                                    },
                                    {
                                        currentValue: libraryDocumentTypes,
                                        defaultValue: []
                                    },
                                    {
                                        currentValue: searchInput,
                                        defaultValue: ""
                                    }
                                ]}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                icon={<SearchOutlined />}
                                style={{
                                    float: "right",
                                }}
                                type="primary"
                                disabled={inputHasChanged === false || !searchInput}
                                onClick={handleSearchInput}
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <LibrarySearchResults
                        resetFiltersFlag={resetFilters}
                        searchData={search}
                        getFolderPathway={getFolderPathway}
                    />
                </div>
            </div>
        </Main>
    );
};