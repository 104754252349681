import React, { useEffect, useState } from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";
import { NumberableDetail } from "../common/NumberableDetail/index.js";
import { SelectableDetail } from "../common/SelectableDetail/index.js";
import { DateableDetail } from "../common/DateableDetail/index.js";
import {
    FREQUENCY_OPTIONS,
    FIRST_PERIOD_OF_YEAR_FIELDS,
    DUE_OFFSET_FIELDS
} from "../../constants/index.js";
import { isoDate, formatTimeValue, isUndefinedOrNull } from "../../utils/functions.js";
import {
    calculateInclusiveEndDate,
    calculateFirstDueDate,
    calculateOffsetFromStartDate,
    setFirstPeriodFromYearStart,
    setFirstPeriodOfYear,
    calculateOffsetFromYearStart
} from "../../utils/dateFunctions.js";
import dayjs from "dayjs";
import { EditableDetail } from "../common/EditableDetail/index.js";

const january1 = moment().startOf("year");

const IntervalSelector = ({
    frequency,
    firstPeriod,
    startDate,
    onStartDateChange,
    disabled,
    isCurrentFutureYear
}) => {


    const endDate = firstPeriod && calculateInclusiveEndDate(firstPeriod, frequency, startDate);

    const isDateDisabled = (current) => {

        if (isCurrentFutureYear) {
            const minDate = startDate;
            let maxDate;
            if (frequency === FREQUENCY_OPTIONS.monthly) {
                maxDate = startDate.clone().add(1, "months");
            } else if (frequency === FREQUENCY_OPTIONS.quarterly) {
                maxDate = startDate.clone().add(3, "months");
            } else if (frequency === FREQUENCY_OPTIONS.yearly) {
                maxDate = startDate.clone().add(1, "year");
            } else if (frequency === FREQUENCY_OPTIONS.biweekly) {
                maxDate = startDate.clone().add(2, "week").add(-1, "days");
            }
            if (current && moment(current)?.isBetween(minDate, maxDate, null, "[]")) {
                return null;
            }
            return current;
        } else {
            if (frequency === FREQUENCY_OPTIONS.monthly) {
                return january1 > current || current >= january1.clone().add(1, "months");
            } else if (frequency === FREQUENCY_OPTIONS.quarterly) {
                return january1 > current || current >= january1.clone().add(1, "quarter");
            } else if (frequency === FREQUENCY_OPTIONS.yearly) {
                return january1 > current || current >= january1.clone().add(1, "year");
            } else if (frequency === FREQUENCY_OPTIONS.biweekly) {
                return january1 > current || current >= january1.clone().add(2, "week");
            }
        }
    };

    if (frequency === FREQUENCY_OPTIONS.weekly) {
        return <>
            {
                firstPeriod &&
                <span>
                    <strong>{formatTimeValue(firstPeriod)}</strong> through <strong>{formatTimeValue(endDate)}</strong> (Sunday through Saturday)
                </span>
            }
        </>;
    }

    return <>
        <DatePicker
            placeholder={"First Period of the Year"}
            value={dayjs(moment(firstPeriod))}
            format={"MM/DD/YYYY"}
            disabledDate={isDateDisabled}
            onChange={onStartDateChange}
            disabled={disabled}
        />
        {
            endDate &&
            <span style={{ marginLeft: "10px" }}>through <strong>{formatTimeValue(endDate)}</strong></span>
        }
    </>;
};

export const ScheduleDetails = ({
    creating,
    submissionType,
    contractTypes,
    frequencyOptions,
    displayedVersion,
    modified,
    setModified,
    canEditSchedule,
    setToDisplay
}) => {

    const submissionTypeDisplayedVersion = !creating ? submissionType?.submissionTypeVersions.find?.(version => version.id === displayedVersion) : null;

    const reportTemplate = (!creating && submissionType?.reportTemplate) ? submissionType?.reportTemplate : null;
    const versions = (!creating && submissionType?.reportTemplate?.versions) ? submissionType?.reportTemplate?.versions : [];
    const activeVersionNumber = (!creating && reportTemplate?.activeVersion) ? reportTemplate?.activeVersion?.versionNumber : null;
    const documentName = !creating ? versions?.find((item) => item.versionNumber === activeVersionNumber)?.name : null;

    const [firstPeriodDay, setFirstPeriodDay] = useState(submissionTypeDisplayedVersion?.periodOffset?.days);
    const [firstPeriodMonth, setFirstPeriodMonth] = useState(submissionTypeDisplayedVersion?.periodOffset?.months);

    const [dueOffset, setDueOffset] = useState({
        days: isUndefinedOrNull(modified.dueDays) && !creating ? submissionTypeDisplayedVersion.dueOffset?.days : (modified.dueDays || 0),
        months: isUndefinedOrNull(modified.dueMonths) && !creating ? submissionTypeDisplayedVersion.dueOffset?.months : (modified.dueMonths || 0)
    });

    const frequency = isUndefinedOrNull(modified.frequency) && !creating
        ? submissionTypeDisplayedVersion.frequency
        : modified.frequency;

    const startDate = isUndefinedOrNull(modified.startDate) && !creating
        ? submissionTypeDisplayedVersion.startDate
        : modified.startDate;


    const adhocDueDate = modified.adhocDueDate === undefined && !creating
        ? submissionTypeDisplayedVersion.adhocDueDate
        : modified.adhocDueDate;

    const periodOffset = {
        days: (isUndefinedOrNull(modified.periodDays) && !creating) ? firstPeriodDay : (modified.periodDays || 0),
        months: (isUndefinedOrNull(modified.periodMonths) && !creating) ? firstPeriodMonth : (modified.periodMonths || 0)
    };

    const isDateDisabled = (current) => {
        return current && current <= moment().add(-1, "days");
    };
    const isAdhocDueDateDisabled = (dateOption) => {
        return (startDate && dateOption < moment(startDate)) || isDateDisabled(dateOption);
    };

    const setStartDate = (startDateValue) => {
        if (adhocDueDate && moment(adhocDueDate) < startDateValue) {
            setModified({
                ...modified,
                adhocDueDate: null,
                startDate: startDateValue
            });
        } else {
            setModified({
                ...modified,
                startDate: startDateValue,
                periodDays: 0,
                periodMonths: 0
            });
            setFirstPeriodDay(0);
            setFirstPeriodMonth(0);

        }
        setToDisplay([]);
    };

    const startDt = isUndefinedOrNull(startDate) ? null : moment(startDate);
    const isCurrentFutureYear = isUndefinedOrNull(startDate) ? false : (startDt.year() >= january1.year());

    const firstPeriod = (isCurrentFutureYear) ?
        setFirstPeriodOfYear(periodOffset, startDt, frequency) :
        setFirstPeriodFromYearStart(periodOffset);

    useEffect(() => {
        setDueOffset({
            days: isUndefinedOrNull(modified.dueDays) && !creating ? submissionTypeDisplayedVersion.dueOffset?.days : (modified.dueDays || 0),
            months: isUndefinedOrNull(modified.dueMonths) && !creating ? submissionTypeDisplayedVersion.dueOffset?.months : (modified.dueMonths || 0)
        });
        setFirstPeriodDay(submissionTypeDisplayedVersion?.periodOffset?.days);
        setFirstPeriodMonth(submissionTypeDisplayedVersion?.periodOffset?.months);
    }, [displayedVersion, modified]);

    const submissionFrequencyField = (
        <SelectableDetail
            title="Submission Frequency"
            passedKey="submission-frequency-selection"
            readOnly={!canEditSchedule}
            value={frequency}
            onValueUpdated={(value) => {
                setModified({
                    ...modified,
                    frequency: value,
                    periodDays: 0,
                    periodMonths: 0
                });
                setFirstPeriodDay(0);
                setFirstPeriodMonth(0);
                setToDisplay([]);
            }}
            options={Object.values(frequencyOptions).map((option) => {
                return {
                    text: option.displayValue,
                    value: option.text
                };
            })}
        />
    );


    const startDateField = (
        <DateableDetail
            title="Start Date (If different than contract start date)"
            key="startDate"
            readOnly={!canEditSchedule}
            value={startDate ? startDate : startDate}
            disabledDate={(current) => {
                const contractStartDate = submissionType?.contractType?.contractTypeNewestVersion?.startDate;
                const contractEndDate = submissionType?.contractType?.contractTypeNewestVersion?.endDate;
                if (contractStartDate) {
                    const startDate = moment(contractStartDate);
                    const endDate = moment(contractEndDate);

                    if (current && moment(current)?.isBetween(startDate, endDate, null, "[]")) {
                        return null;
                    }
                    return current;
                } else {
                    //Create : validation for contract start date
                    if (modified.contractTypeId) {
                        const contractedSelected = contractTypes.find(({ id }) => {
                            return modified.contractTypeId === id;
                        });
                        if (contractedSelected) {
                            const startDate = moment(contractedSelected.startDate);
                            const endDate = moment(contractedSelected.endDate);

                            if (current && moment(current)?.isBetween(startDate, endDate, null, "[]")) {
                                return null;
                            }
                            return current;
                        }
                    }
                    return false;
                }
            }}
            onValueUpdated={(value) => {
                setStartDate(value);
            }}
        />
    );

    const adHocDueDateField = () => {
        const showAdhocDueDateDetail = (
            // creating ST w/ ad hoc frequency; or 
            (creating && modified.frequency === FREQUENCY_OPTIONS.adhoc) ||
            // not creating, can edit schedule, and frequency is being changed to ad hoc; or 
            (!creating && canEditSchedule && modified.frequency === FREQUENCY_OPTIONS.adhoc) ||
            // not creating, or editing, but the current frequency is ad hoc and there is an adhoc due date 
            (!creating && frequency === FREQUENCY_OPTIONS.adhoc && adhocDueDate !== undefined)
        );
        return showAdhocDueDateDetail && (
            <div style={{ marginTop: "15px" }}>
                <DateableDetail
                    title="Adhoc Due Date (Optional)"
                    key="adhocDueDate"
                    required={false}
                    readOnly={creating ? false : !canEditSchedule}
                    disabledDate={isAdhocDueDateDisabled}
                    value={adhocDueDate ? adhocDueDate : null}
                    onValueUpdated={(value) => {
                        setModified({
                            ...modified,
                            adhocDueDate: value ? isoDate(value) : null
                        });
                    }}
                />
            </div>
        );
    };

    const firstPeriodOfYearField = () => {

        const showReportingPeriod = FIRST_PERIOD_OF_YEAR_FIELDS.includes(frequency);
        if (!showReportingPeriod) {
            return;
        }

        return (
            <>
                <div style={{ display: showReportingPeriod ? "inherit" : "none" }}>
                    <div className="header__label--sae">
                        First Period of the Year
                    </div>
                    <IntervalSelector
                        disabled={!canEditSchedule}
                        firstPeriod={firstPeriod}
                        frequency={frequency}
                        startDate={startDt}
                        isCurrentFutureYear={isCurrentFutureYear}
                        onStartDateChange={(value) => {
                            if (value) {
                                const { days, months } = (isCurrentFutureYear) ?
                                    calculateOffsetFromStartDate(value, startDt, frequency) :
                                    calculateOffsetFromYearStart(value);
                                setModified({
                                    ...modified,
                                    periodMonths: months,
                                    periodDays: days,
                                    firstPeriodDate: value
                                });
                                setFirstPeriodDay(days);
                                setFirstPeriodMonth(months);
                            } else {
                                setModified({
                                    ...modified,
                                    periodMonths: 0,
                                    periodDays: 0
                                });
                            }
                            setToDisplay(["periodMonths", "periodDays"]);
                        }}
                    />
                    <p>
                        The first full reporting period of the calendar year.  This defines the submission schedule for the duration of the contract.
                    </p>

                </div>
            </>
        );
    };

    const dueOffSetField = () => {
        const showDueOffset = DUE_OFFSET_FIELDS.includes(frequency);
        return showDueOffset && (
            <>
                <div className="header__label--sae">
                    Due Offset
                </div>
                {[
                    {
                        title: "Calendar Days",
                        key: "dueDays",
                        passedMax: 364,
                        valueKey: "days"
                    },
                    {
                        title: "Months",
                        key: "dueMonths",
                        passedMax: 11,
                        valueKey: "months"
                    }
                ].map(({ title, key, passedMin, passedMax, placeholder, valueKey }) => {
                    return (
                        <span key={`${title}-${key}`}>
                            <NumberableDetail
                                title={title}
                                disabled={!canEditSchedule}
                                passedMin={passedMin ?? 0}
                                passedMax={passedMax ?? 1000}
                                placeholder={placeholder || 0}
                                strict={true}
                                value={dueOffset[valueKey]}
                                onValueUpdated={(v) => {
                                    setModified({
                                        ...modified,
                                        [key]: v
                                    });
                                }}
                            />
                        </span>
                    );
                })}
                <p>
                    The Due Offset is the amount of time after the end of each period that the organization has before this submission is due.
                </p>
                <p className="spaceAbove-xs">Due Date for first period of the year:
                    <strong>{` ${formatTimeValue(calculateFirstDueDate(frequency, dueOffset, startDate, firstPeriod))}`}</strong>
                </p>
            </>

        );

    };

    return (
        <div>
            {submissionFrequencyField}


            {documentName &&
                <Form
                    layout={"vertical"}>
                    <EditableDetail
                        title="Report Template"
                        value={documentName}
                        readOnly={true}
                    />
                </Form>
            }

            {frequency !== FREQUENCY_OPTIONS.adhoc &&
                <p>
                    This submission type will be due on the selected Submission Frequency for every applicable date within the effective dates of the contract.
                </p>
            }

            {startDateField}
            {adHocDueDateField()}
            {firstPeriodOfYearField()}
            {dueOffSetField()}
        </div>
    );
};